import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Form, Select, Tabs, Input, Spin, Modal, DatePicker, message, Table } from "antd";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import { v4 as uuid } from "uuid";
import {useGlobalContext} from '../../../lib/storage'
import { getStReceiptBusinessunit, getSTIssueDocumentData,getStDocumentProducts } from "../../../services/generic";
import Axios from "axios";
import { serverUrl, genericUrl, fileDownloadUrl } from "../../../constants/serverConfig";

import moment from "moment";

const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";

const STReceipt = (props) => {
  const { globalStore } = useGlobalContext();
  const [businessUnitList, setBusinessUnitList] = useState([]);
  const [sTReceiptData,setSTReceiptData] = useState([])
  const [bunitId, setBunitId] = useState("");
  const [businessUnitName, setBusinessUnitName] = useState("");
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [tabKey, setTabKey] = useState("10");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedProductObject, setSelectedProductObject] = useState({});
  const [inventoryCountSummaryData, setInventoryCountSummaryData] = useState([]);
  const [inventorySummaryReviewVisible, setInventorySummaryReviewVisible] = useState(false);
  // const [cwrWareHouseIdForIssueBU, setCwrWareHouseIdForIssueBU] = useState("");
  const [doctypeId, setDoctypeId] = useState("");
  // const [cwrWareHouseIdForRecevingBU, setCwrWareHouseIdForRecevingBU] = useState("");
  const [clientId,setClientId] = useState(null)
  const [userId,setUserId]= useState(null)
  const [mtranseferReceiptId,setMtranseferReceiptId] = useState(null)

  const [form] = Form.useForm();
  const [headerform] = Form.useForm();
  const [summaryForm] = Form.useForm();

  useEffect(() => {
    const initialDate = moment(new Date()).format("YYYY-MM-DD");
    setSelectedDate(initialDate);
    setClientId(globalStore.userData.cs_client_id)
    setUserId(globalStore.userData.user_id)
    headerform.setFieldsValue({
      date: moment(initialDate, dateFormat),
    });
    summaryForm.setFieldsValue({
      summbusinessunit: businessUnitName,
      summreceiptdate: moment(initialDate, dateFormat),
    });
  }, []);
  
  const onFinish = () => {};

  const inventorySummaryReview = () => {
    callbackTabs()
      summaryForm.setFieldsValue({
        summbusinessunit: businessUnitName,
        summreceiptdate: moment(selectedDate, dateFormat),
      });
      const newArray = [];
      for (let index = 0; index < productData.length; index++) {
        const element = parseFloat(productData[index]['5509551F9A19437182D40CAD9754BDF7']);
        if (element > 0) {
          newArray.push(productData[index]);
        }
      }
      setTimeout(() => {
      setInventoryCountSummaryData([])
      setInventoryCountSummaryData(newArray);
    }, 200);
      if (newArray.length > 0) {
        let flag = true;
        for (let index = 0; index < newArray.length; index++) {
          const totalOnHandQty = parseFloat(newArray[index]['5509551F9A19437182D40CAD9754BDF7']);
          const issueQty = parseFloat(newArray[index].issuedQty);
          if (totalOnHandQty > issueQty) {
            flag = false;
          }
        }
        if (flag === true) {
          setInventorySummaryReviewVisible(true);
        } else {
          message.error("Receiving Qty should not be greater than issued qty");
        }
      } else {
        message.error("Please Add Products");
      }
  };
  const getStReceiptBusinessUnit = async () => {
    const stReceiptBUnitResponse = await getStReceiptBusinessunit();
    setBusinessUnitList(stReceiptBUnitResponse)
  };

  const onSelectBusinessUnit = async (e, data) => {
    // warehouseid
    const { title, doctypeid } = data.props;
    setBunitId(e);
    setBusinessUnitName(title);
    // setCwrWareHouseIdForIssueBU(warehouseid);
    setDoctypeId(doctypeid);
    const stIssueDocumentData = await getSTIssueDocumentData(e);
    setSTReceiptData(stIssueDocumentData)
  };

  const getProductsOfStDocument =  async (e) =>{
     setLoading(true)
     const stDocumentResponseData = await getStDocumentProducts(e);
     const data = stDocumentResponseData['data']
      for (let index = 0; index < data.length; index++) {
        const uniqueId = uuid()
      .replace(/-/g, '')
      .toUpperCase()
        data[index].issuedQty = data[index]['5509551F9A19437182D40CAD9754BDF7'];
        data[index].key = uniqueId;        
      }
     setProductData(data)
     setMtranseferReceiptId(e)
     setLoading(false)
  }

  const getDateChange = (date, dateString) => {
    setSelectedDate(dateString);
    summaryForm.setFieldsValue({
      summreceiptdate: moment(dateString, dateFormat),
    });
  };
  
  const rowSelectedProduct = (data) => {
    setSelectedProductObject(data);
    form.setFieldsValue({
      'C3B426F6C063462BAB008A2E7A5D955F_iden': data['C3B426F6C063462BAB008A2E7A5D955F_iden'],
      'ACF47A965B0E4922A43CBC77524062D0_iden': data['ACF47A965B0E4922A43CBC77524062D0_iden'],
      '5509551F9A19437182D40CAD9754BDF7':data['5509551F9A19437182D40CAD9754BDF7'],
      'issuedQty': data.issuedQty,
    });
  };

  const callbackTabs = (key) => {
    setTabKey(key);
    summaryForm.setFieldsValue({
      summbusinessunit: businessUnitName,
      summreceiptdate: moment(selectedDate, dateFormat),
    });
    const newArray = [];
    for (let index = 0; index < productData.length; index++) {
      const element = parseFloat(productData[index].issuedQty);
      if (element > 0) {
        newArray.push(productData[index]);
      }
    }
    setInventoryCountSummaryData(newArray);
  };
  const OnChangeOrderQty = () => {
    setTimeout(() => {
    const formFieldsData = form.getFieldsValue(true);
    const issuedQty = parseFloat(formFieldsData.issuedQty);
    const totalOnHandQty = parseFloat(formFieldsData['5509551F9A19437182D40CAD9754BDF7']);
    const selectedMProductId = selectedProductObject.productId;
    const selectedCsUomId = selectedProductObject.uomId;
    if (totalOnHandQty > issuedQty) {
      message.error("Receiving Qty should not be greater than issued qty");
      selectedProductObject['5509551F9A19437182D40CAD9754BDF7'] = issuedQty;
      const index = productData.findIndex((element) => {
        return element.productId === selectedMProductId && element.uomId === selectedCsUomId;
      });
      const newArray = [];
      if (index >= 0) {
        productData.splice(index, 1);
      }
      for (let index = 0; index < productData.length; index++) {
        newArray.push(productData[index]);
      }
      newArray.unshift(selectedProductObject);
      setTimeout(() => {
        setProductData([])
        setProductData(newArray)
      }, 1);
    } else {
      selectedProductObject['5509551F9A19437182D40CAD9754BDF7'] = totalOnHandQty;
      const index = productData.findIndex((element) => {
        return element.productId === selectedMProductId && element.uomId === selectedCsUomId;
      });
      const newArray = [];
      if (index >= 0) {
        productData.splice(index, 1);
      }
      for (let index = 0; index < productData.length; index++) {
        newArray.push(productData[index]);
      }
      newArray.unshift(selectedProductObject);
      setTimeout(() => {
        setProductData([])
        setProductData(newArray)
      }, 1);
    }
  }, 1);
  };

  

  const getPrintCofirmation = (recordId, messageForSuccess, displayDocNo) => {
    Modal.confirm({
      title: messageForSuccess + ":" + displayDocNo,
      content: "Do you want take Printout",
      okText: "Yes",
      cancelText: "No",
      icon:null,
      onOk() {
        getPrintPdffile(recordId);
      },
      onCancel() {
        setInventorySummaryReviewVisible(false);
        setProductData([]);
      },
    });
  };

  const getPrintPdffile = (recordId) => {
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    const RoleId = window.localStorage.getItem("userData");
    const getPrintPdfData = {
      query: `query {reportTemplate(ad_tab_id:"EE797F0AD47E41A08CFBC7867F538661",recordId:"${recordId}")}`,
    };
    Axios({
      url: genericUrl,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getPrintPdfData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
        RoleId: `${RoleId.role_id}`,
      },
    }).then((response) => {
      if (response.data.data.reportTemplate === null || response.data.data.reportTemplate === "null" || response.data.data.reportTemplate === "") {
        message.error("Error");
      } else {
        getPrintCommand(response.data.data.reportTemplate);
      }
    });
  };

  const getPrintCommand = (fileName) => {
    setProductData([]);
    setInventorySummaryReviewVisible(false);
    Axios({
      url: `${fileDownloadUrl}`.concat(`${fileName}`),
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.setAttribute("id", "downloadlink");
      link.href = fileURL;
      link.setAttribute("download", `${fileName}`);
      link.click();
    });
  };

  const closeSummaryReview = () => {
    setInventorySummaryReviewVisible(false);
  };

  const tableColumns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      render: (text, row) => (
        <span
          style={{ cursor: "pointer" }}
          role="presentation"
          onClick={() => {
            rowSelectedProduct(text);
          }}
        >
          <EditOutlined />
        </span>
      ),
    },
    // {
    //   title: "SKU",
    //   dataIndex: "searchKey",
    //   width: 80,
    // },
    {
      title: "Product Name",
      dataIndex: "C3B426F6C063462BAB008A2E7A5D955F_iden",
      width: 150,
    },
    {
      title: "UOM",
      dataIndex: "ACF47A965B0E4922A43CBC77524062D0_iden",
      width: 80,
    },
    {
      title: "Issued Qty",
      dataIndex: "issuedQty",
      width: 80,
    },
    {
      title: "Receiving Qty",
      dataIndex: "5509551F9A19437182D40CAD9754BDF7",
      width: 120,
    },
  ];


  const createStockTransferIssue = () =>{
    setLoading(true)
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    let productID = ''
    let uomID = ''
    let quantity = ''
    let useToSToargeBin = ''
    let mTransferReceiptId = ''
    let batchId = ''
    const arrayForMutation = []
        for (let index = 0; index < inventoryCountSummaryData.length; index += 1) {
          if (inventoryCountSummaryData[index]['5509551F9A19437182D40CAD9754BDF7'] !== undefined) {
            productID = inventoryCountSummaryData[index]['C3B426F6C063462BAB008A2E7A5D955F']
            uomID = inventoryCountSummaryData[index]['ACF47A965B0E4922A43CBC77524062D0']
            quantity = inventoryCountSummaryData[index]['5509551F9A19437182D40CAD9754BDF7'] === undefined ? 0 : inventoryCountSummaryData[index]['5509551F9A19437182D40CAD9754BDF7']
            useToSToargeBin = inventoryCountSummaryData[index]['AF1CD10275C54C528B8025A37909CF20']
            mTransferReceiptId = inventoryCountSummaryData[index]['C86564B3E45648889920F9D4EA74AD0F']
            batchId = inventoryCountSummaryData[index]['6807C1DEED7F4834AED8285EB80A569A']
            arrayForMutation.push(
              `{
                  mTransferReceiptLineID:"${mTransferReceiptId}"
                  mProductID : "${productID}"
                  csUomId : "${uomID}"
                  qtyReceived : ${quantity}
                  mStoragebintoID:"${useToSToargeBin}",
                  productBatch:${batchId === null ||batchId === undefined ? null : `"${batchId}"`}
                  }`,
            )
          }
        }
        let currentIssueDate = moment(selectedDate).format('YYYY-MM-DD')
        const createSTTransferReceipt = {
          query: `mutation {insertSTtransferReceipt(receipt:{
                  mTransferreceiptID:"${mtranseferReceiptId}",
                  cSBunitID : "${bunitId}",
                  cSClientID:"${clientId}",
                  createdby : "${userId}"
                  csDoctypeId: "${doctypeId}",
                   receiptDate: "${currentIssueDate}",
                   mStoragebinId:"${useToSToargeBin}"
                  stockTransferIssue: null,    
                  lines:[${arrayForMutation}]
              }
              
              ) {
                     type
                      message
                      mTransferreceiptID
                      documentno
                      cSClientID
                      cSBunitID
                      csDoctypeId
                      receiptDate
                      mStoragebinId
                      stockTransferIssue
                 }
              }	`,
        }
        Axios({
          url: serverUrl,
          method: 'POST',
          data: createSTTransferReceipt,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${newToken.access_token}`,
          },
        }).then(response => {
          if (response.status === 200) {
            let mTransferreceiptID = response.data.data.insertSTtransferReceipt.mTransferreceiptID    
            const processSTReceipt = {
              query: `mutation {processStransferReceipt(process:{
                  cSClientID:"${clientId}",
                  createdby : "${userId}",
                  cSBunitID : "${bunitId}",
                    mTransferreceiptID:"${mTransferreceiptID}"
                
                }) {
                         message
                         documentNo
                   }
                }`,
            }
            Axios({
              url: serverUrl,
              method: 'POST',
              data: processSTReceipt,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `bearer ${newToken.access_token}`,
              },
            }).then(response => {
              if (response.status === 200) {
                let documentNo = response.data.data.processStransferReceipt.documentNo
                let messageToDisplay = response.data.data.processStransferReceipt.message
                setLoading(false)
                setProductData([])
                setInventorySummaryReviewVisible(false)
                getPrintCofirmation(mTransferreceiptID, messageToDisplay, documentNo)
                form.resetFields()
                form.resetFields([
                    'C3B426F6C063462BAB008A2E7A5D955F_iden',
                    'ACF47A965B0E4922A43CBC77524062D0_iden',
                    'issueqty',
                    '5509551F9A19437182D40CAD9754BDF7',        
                ])
                // this.setState({
                //   selectedGridRecordForForm:[],
                //   inventorySummaryReviewVisible: false,
                //   receivePopupGridData: []
                // })
              }
            })
          }
        })
  }

  let totalQuantity = 0;
  let totalQuantity1;
  for (let index = 0; index < inventoryCountSummaryData.length; index++) {
    totalQuantity1 = inventoryCountSummaryData[index]['5509551F9A19437182D40CAD9754BDF7'];
    const integer = parseFloat(totalQuantity1, 10);
    totalQuantity += integer;
  }

  const summaryDiv = (
    <Card>
      <Form layout="vertical" form={summaryForm} name="summaryForm" onFinish={onFinish}>
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Form.Item name="summbusinessunit" label="Business Unit">
              <Input readOnly style={{ borderLeft: "none", borderTop: "none", borderRight: "none" }} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item name="summreceiptdate" label="Date">
              <DatePicker style={{ width: "100%", borderLeft: "none", borderTop: "none", borderRight: "none" }} onChange={getDateChange} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row>
        <p />
        <br />
      </Row>
      <Row>
        <Table
          columns={tableColumns}
          dataSource={inventoryCountSummaryData}
          style={{ fontSize: "12px" }}
          size="small"
          sticky={true}
          scroll={{ y: "40vh", x: "100%" }}
          pagination={false}
        />
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <h4>No. of Products: {inventoryCountSummaryData.length}</h4>
        </Col>
        <Col className="gutter-row" span={6} />
        <Col className="gutter-row" span={6} />
        <Col className="gutter-row" span={6}>
          <h4>Total Qty: {totalQuantity}</h4>
        </Col>
      </Row>
    </Card>
  );



  return (
    <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
      <div>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>New Stock Receipt</h2>
          </Col>
          <Col span={12}>
            <span style={{ float: "right" }}>
              <Button onClick={inventorySummaryReview} style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>
                Review
              </Button>
            </span>
          </Col>
        </Row>
        <Card style={{ marginBottom: "8px" }}>
          <Form layout="vertical" form={headerform} name="control-hooks" onFinish={onFinish}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item name="businessunit" label="Business Unit" style={{ marginBottom: "8px" }}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getStReceiptBusinessUnit}
                    onSelect={onSelectBusinessUnit}
                  >
                    {businessUnitList.map((data, index) => (
                      <Option key={data.recordid} title={data.name} value={data.cs_bunit_id} doctypeid={data.cs_srdoctype_id} warehouseid={data.cwr_m_warehouse_id}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item name="stissueDoc" label="ST Issue Document" style={{ marginBottom: "8px" }}>
                  <Select 
                  onSelect={getProductsOfStDocument} 
                  allowClear 
                  showSearch 
                  filterOption={(input, option) => option.children === null  || option.children === undefined ? null :  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {sTReceiptData.map((data, index) => (
                      <Option key={data.m_transferreceipt_id} title={data.issueno} value={data.m_transferreceipt_id}>
                        {data.issueno}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item name="date" label="Date" style={{ marginBottom: "8px" }}>
                  <DatePicker style={{ width: "100%" }} onChange={getDateChange} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <div>
          <Tabs defaultActiveKey={tabKey} onChange={callbackTabs} type="card" tabBarStyle={{ marginBottom: "0px" }}>
            <TabPane tab="Products" key="10">
              <Card style={{ marginBottom: "8px" }}>
                <Form layout="vertical" form={form} name="editable-form" onFinish={onFinish}>
                  <Row gutter={16}>
                    {/* <Col className="gutter-row" span={4}>
                      <Form.Item name="skuValue" label="SKU" style={{ marginBottom: "8px" }}>
                        <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                      </Form.Item>
                    </Col> */}
                    <Col className="gutter-row" span={4}>
                      <Form.Item name="C3B426F6C063462BAB008A2E7A5D955F_iden" label="Name" style={{ marginBottom: "8px" }}>
                        <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item name="ACF47A965B0E4922A43CBC77524062D0_iden" label="UOM" style={{ marginBottom: "8px" }}>
                        <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item name="issuedQty" label="Issued Qty" style={{ marginBottom: "8px" }}>
                        <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item name="5509551F9A19437182D40CAD9754BDF7" label="Receiving Qty" style={{ marginBottom: "8px" }}>
                        <Input onChange={OnChangeOrderQty} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Form.Item name="6807C1DEED7F4834AED8285EB80A569A_iden" label="Batch No" style={{ marginBottom: "8px" }}>
                        <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
              <Card>
              <Table
                rowClassName={(record, index) => (record.key === selectedProductObject.key ? "table-row-dark" : "table-row-light")}
                columns={tableColumns}
                dataSource={productData}
                style={{ fontSize: "12px" }}
                size="small"
                sticky={true}
                scroll={{ y: "39vh", x: "100%" }}
                pagination={false}
              />
              </Card>
            </TabPane>
            <TabPane tab="Summary" key="13">
              {summaryDiv}
            </TabPane>
          </Tabs>
        </div>
      </div>
      <div>
        <Modal
          visible={inventorySummaryReviewVisible}
          closable={null}
          centered
          width="80%"
          footer={[
            <Button key="back" onClick={closeSummaryReview}>
              Preview
            </Button>,
            <Button loading={loading} htmlType="submit" onClick={createStockTransferIssue} >
              Confirm
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>Stock Receipt Summary</h3>
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
            {summaryDiv}
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};

export default STReceipt;
