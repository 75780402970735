/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Spin, Select, DatePicker, Form, message,  Menu, Button, Modal,Checkbox,Switch } from "antd";
import Export from "../../assets/images/exportReport.svg";
import switchPivot from "../../assets/images/switchPivot.svg";
import TableForReport from './TableForReport'
// import NumberFormat from 'react-number-format'
import { useGlobalContext } from "../../lib/storage";
import moment from 'moment'
import { LoadingOutlined } from "@ant-design/icons";
import { genericUrl,fileDownloadUrl } from "../../constants/serverConfig.js";
import { getComboFillForReportOnlyId,getComboFillForReportIdAndValue } from "../../services/generic";
import Axios from "axios";
import TableRenderers from 'react-pivottable/TableRenderers'
import createPlotlyComponent from 'react-plotly.js/factory'
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers'
import PivotTableUI from 'react-pivottable/PivotTableUI'
import 'react-pivottable/pivottable.css'
import "antd/dist/antd.css";
import "../../styles/app.css";
import styles from "./style.css"
// import "../../styles/antd.css"


const { RangePicker } = DatePicker;

const { Option } = Select;
// const dateFormat = "YYYY-MM-DD";

const Plot = createPlotlyComponent(window.Plotly)
const PlotlyRenderers = createPlotlyRenderers(Plot)

import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

const Report = () => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const userPreferences = globalStore.userPreferences;
  const dateFormat = userPreferences.dateFormat
  const { reportId } = useParams();
  const [gridLength, setGridLength] = useState(0);
  const [reportName, setReportName] = useState("");
  const [parameters, setParameters] = useState("")
  const [reportFilters, setReportFilters] = useState([]);
  const [reportFields, setReportFields] = useState([]);
  const [dropdownDetails, setDropdownDetails] = useState([]);
  const [referenceListDetails, setListReferenceDetails] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [drillDownHeader,setHeaderForDrillDown ] = useState([]);
  const [drillDownData, setDataForDrillDown] = useState([]);
  const [drillDownPopup, setPopupForDrillDown] = useState(false);
  const [drillDownTitle, setDrillDownTitle] = useState("");
  // const [summaryFields, setSummaryFields] = useState([]);
  // const [summaryFieldsForDrillDown, setSummaryFieldsForDrillDown] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingForModal, setLoadingForModal] = useState(false);
  const [fetching,setFetching] = useState(false)
  const [multiSelectorDropdownId,setMultiSelectorDropdownId] = useState("")
  const [popUpReportName, setPopUpReportName] = useState("");
  const [checkedValue, setCheckedValue] = useState(0);
  const [switchValue, setSwitchValue] = useState(false);
  const [pivotData, setPivotData] = useState();
  const [reportParameters,setReportParameters]=useState("")
  const [showSwitchAndExportButton,setSwitchAndExportButton]=useState(false)
  const [drillDownId, setDrillDownId]=useState("")
  const [showGridData,setShowGridData]=useState(false)
  const [form] = Form.useForm();

  useEffect(() => {
    if (reportId) {
      getReportData();
    }
    return () => {
      setHeaderData([]);
      setGridData([]);
      setSwitchAndExportButton(false)
      setSwitchValue(false)
      setShowGridData(false)
      form.resetFields();
    };
  }, [reportId]);

  const getReportData = async () => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const getReportDetailsQuery = {
        query: `query {
        getReportJson(reportId:"${reportId}"){data, messageCode, title, message}
        }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(genericUrl, getReportDetailsQuery, { headers: headers }, { async: true }, { crossDomain: true });
      const reportJsonResponse = serverResponse.data.data.getReportJson;
      
      if (reportJsonResponse.messageCode === "200") {
        const reportData = JSON.parse(reportJsonResponse.data);
        const reportName = reportData.name;
        const reportFilters = reportData.Filters;
        const reportFields = reportData.Fields;
        setLoading(false);
        setReportName(reportName);
        setReportFilters(reportFilters);
        setReportFields(reportFields);
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onDropDownSelect = async (id) => {
    setMultiSelectorDropdownId(id);
    setDropdownDetails([]);
    const getReportData = await getComboFillForReportOnlyId(id);
    setDropdownDetails([...getReportData]);
  };

  const onSearchFunction = async (value) => {
    const getReportData = await getComboFillForReportIdAndValue(multiSelectorDropdownId,value);
    setDropdownDetails([...getReportData]);
  };

  const getData = event => {
    onSearchFunction(event)
  }

  const debounce = function(fn, d) {
    let timer
    return function() {
      let context = this,
      args = arguments
      clearTimeout(timer)
      timer = setTimeout(() => {
        getData.apply(context, arguments)
      }, d)
    }
  }

  const searchDropdownRecords = debounce(getData, 300)



  /* useEffect(()=>{
    // setFetching(false)
  },[dropdownDetails]) */


  const onDropDownSelectForListReference = (values) => {
    // setTimeout(() => (setListReferenceDetails([...values])),1000);
    setListReferenceDetails([...values])
  };

  const responsiveDesignForColumn = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 12,
    sm: 12,
    md: 12,
  };

  const handleMenuClick = (obj) => {
    if (obj.key === "view") {
      form.submit();
    } else {
      // form.submit();
      downloadData();
    }
  };

  /* const onFinish=async(values)=>{
    console.log("===values===",values)
    console.log("===>",form.getFieldsValue(true))
  } */

  const onFinish = async (values) => {
    try {
      /* Object.entries(values).map(([key, value]) => {
        if (dayjs.isDayjs(value)) {
          values[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
        }
      }) */
      // console.log("===values==",values)
      setGridData([]);
      setHeaderData([]);
      setLoading(true);
      const valuesArray = Object.values(values);
      const arrayToSend = {};
      let flagValue = ''
      if (checkedValue === 1) {
        flagValue = "Y";
      } else {
        flagValue = "N";
      }
      let valuesData = "";
      for (let index = 0; index < reportFilters.length; index += 1) {
        if (reportFilters[index].type === "Date") {
          valuesData = new Date(valuesArray[index]).toISOString();
        } else if (reportFilters[index].type === 'Flag') {
          valuesData = flagValue
      } else {
          valuesData = valuesArray[index];
        }
        arrayToSend[reportFilters[index].columnName] = valuesData === undefined ? null : valuesData;
      }
      const stringifiedJSON = JSON.stringify(arrayToSend);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const onSubmitQuery = {
        query: `query {
        executeReport(reportId:"${reportId}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
      }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      const serverResponse = await Axios.post(genericUrl, onSubmitQuery, { headers: headers }, { async: true }, { crossDomain: true });

      if (serverResponse.status === 200) {   
             
        const responseForGridData = serverResponse.data.data.executeReport.data;
        const gridData = JSON.parse(responseForGridData);
        const reportsGridArray = [];
        const headerArray = [];
        const finalArrayToPush = [];
        // const summaryFieldsArr = []
        let dataArray;

        if (gridData.length > 0) {
          /* reportFields.sort(function(a, b) {
            return a.sequence_no - b.sequence_no
          }) */

          for (let index = 0; index < gridData.length; index += 1) {
            // console.log("=======gridData=========",typeof(gridData[index]))
            if (typeof gridData[index] === "string" ) {
              dataArray = JSON.parse(gridData[index]);
              reportsGridArray.push(dataArray);
            } else {
              dataArray = gridData[index];
              reportsGridArray.push(dataArray);
            }
          }



          for (let index = 0; index < reportsGridArray.length; index++) {
            const gridData = reportsGridArray[index];
            for (let indexF = 0; indexF < reportFields.length; indexF++) {
              const fieldData = reportFields[indexF];
              // console.log("===reportFields===",fieldData)
              /* if(fieldData.type==="Numeric"){
                const fieldName = fieldData.fieldName
                gridData[fieldName]=gridData[fieldName].toLocaleString('en-US')
              } */
              if(fieldData.type==="String"){
                const fieldName = fieldData.fieldName
                gridData[fieldName]= gridData[fieldName]===null || gridData[fieldName]===undefined ? '' : (gridData[fieldName]).toString()
              }
              if(fieldData.type==="List"){
                const fieldName = fieldData.fieldName
                const valuesArr = fieldData.Values
                for (let indexV = 0; indexV < valuesArr.length; indexV++) {
                  const valueElement = valuesArr[indexV];    
                  if(valueElement.key===gridData[fieldName]) {
                    gridData[fieldName]=valueElement.value
                  }       
                }
              }
              if(fieldData.type==="Yes/No"){
                const fieldName = fieldData.fieldName
                if(gridData[fieldName]==="N"){
                  gridData[fieldName]="No"
                }else{
                  gridData[fieldName]="Yes"
                }
              }
              if(fieldData.type==="WYSIWYG Editor"){
                const fieldName = fieldData.fieldName
                gridData[fieldName] = <div dangerouslySetInnerHTML={{ __html: gridData[fieldName] }}></div>
              }
            }
          }

          
          
          const keysArray = Object.keys(reportsGridArray[0]);         
          for (let i = 0; i < keysArray.length; i += 1) {
            for (let index = 0; index < reportFields.length; index += 1) {             
              if (keysArray[i] === reportFields[index].fieldName) {
                finalArrayToPush.push(reportFields[index]);                
                // summaryFieldsArr.push(reportFields[index].fieldName);
                break;
              }              
            }
          }

          finalArrayToPush.sort(function (a, b) {
            return a.sequence_no - b.sequence_no;
          });


          for (let index = 0; index < finalArrayToPush.length; index += 1) {
            headerArray.push({
              // headerName: filtersData[index].displayName,
              title: <div className="dragHandler" style={{textAlign:finalArrayToPush[index].type==="Numeric"?"right":"left"}}>{finalArrayToPush[index].displayName}</div>,
              dataIndex: finalArrayToPush[index].fieldName,
              type: finalArrayToPush[index].type,
              // key: index,
              width: finalArrayToPush[index].gridlength===undefined || finalArrayToPush[index].gridlength===null ? 100/* parseInt((finalArrayToPush[index].displayName).length+20) */ : parseInt(finalArrayToPush[index].gridlength) ,
              ellipsis: true,
              render: (text) => finalArrayToPush[index].drillDown==="Y"?<a>{text}</a>:<div style={{textAlign:finalArrayToPush[index].type==="Numeric"?"right":"left"}}>{finalArrayToPush[index].type==="Numeric"?text===undefined || text===null ?'':text.toLocaleString('en-US'):text}</div>,
              onCell: (record) => ({
                onClick: () => {
                  drillDown(jsonToSend,finalArrayToPush[index].fieldName,record[finalArrayToPush[index].fieldName],finalArrayToPush[index].detailReportId)
                },
              })
              /* render: (text, record, index) => {
                console.log("text, record, index",text, record, index)
              } */
            });
            // pivotDisplayNameArray.push(finalArrayToPush[index].displayName)
          }
          setGridData(reportsGridArray);
          setHeaderData(headerArray);
          setLoading(false);
          setParameters(jsonToSend)
          setShowGridData(true)
          setSwitchAndExportButton(true)
          // setSummaryFields(summaryFieldsArr)
        }else{
          message.error("No Data Available")
          setLoading(false);
          setShowGridData(false)
        }
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const drillDown = async (parametersData,fieldName,record,drillDownId)=>{   
    try {    
      const parametersFromState = parametersData
      const replacedString = parametersFromState.replace(/\\/g, '')
      const parsedJson = JSON.parse(replacedString)
      parsedJson[fieldName]=record
      for (const [key, value] of Object.entries(parsedJson)) {
        if(value===null){
          delete parsedJson[key]
        }
      }

      const stringifiedJSON = JSON.stringify(parsedJson);
      
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      setReportParameters(jsonToSend)
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      let dataArray;
      let reportsGridArray=[]
      const headerArrayForDrillDown = []
      // const summaryFieldsForDrillDown = []
      const finalArrayToPush = []
      // const summaryFieldsArr = []

      if(drillDownId!==null){
        setDrillDownId(drillDownId)
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `bearer ${newToken.access_token}`,
        };
        setLoadingForModal(true)
        const getReportDetailsQuery = {
          query: `query {
          getReportJson(reportId:"${drillDownId}"){data, messageCode, title, message}
          }`,
        };
  
        const getReportJsonServerResponse = await Axios.post(genericUrl, getReportDetailsQuery, { headers: headers }, { async: true }, { crossDomain: true });
        const reportJsonResponse = getReportJsonServerResponse.data.data.getReportJson;
        
        if (reportJsonResponse.messageCode === "201") {
          // console.log("=======>Drill Down Id<=====",drillDownId)
          setLoading(false)
          message.error(reportJsonResponse.message)
        }else{
          const reportData = JSON.parse(reportJsonResponse.data);
          const reportName = reportData.name;
          setPopUpReportName(reportName)
          const drillDownFields = reportData.Fields
          const onSubmitQuery = {
            query: `query {
            executeReport(parentReportId:"${reportId}",reportId:"${drillDownId}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
          }`,
          };
          
          const serverResponse = await Axios.post(genericUrl, onSubmitQuery, { headers: headers }, { async: true }, { crossDomain: true });
          const reportResponse = serverResponse.data.data.executeReport
          if(reportResponse.title==="Success"){
          setLoading(false);
          setLoadingForModal(false)
          const gridData = JSON.parse(reportResponse.data);
          // console.log("=====>gridData<======",gridData.length)
          setGridLength(gridData.length)
          if (gridData.length > 0) {
            for (let index = 0; index < gridData.length; index += 1) {
              // console.log("=======gridData=========",typeof(gridData[index]))
              if (typeof gridData[index] === "string") {
                dataArray = JSON.parse(gridData[index]);
                reportsGridArray.push(dataArray);
              } else {
                dataArray = gridData[index];
                reportsGridArray.push(dataArray);
              }
            }

            for (let index = 0; index < reportsGridArray.length; index++) {
              const gridData = reportsGridArray[index];
              for (let indexF = 0; indexF < drillDownFields.length; indexF++) {
                const fieldData = drillDownFields[indexF];

                /* if(fieldData.type==="Numeric"){
                  const fieldName = fieldData.fieldName
                  gridData[fieldName]=gridData[fieldName].toLocaleString('en-US')
                } */



                if(fieldData.type==="String"){
                  const fieldName = fieldData.fieldName
                  // console.log("===fieldName==",fieldName)
                  // toString(gridData[fieldName])
                  // console.log("===gridData==",gridData)
                  // console.log("===gridData[fieldName]==",(gridData[fieldName]).toString())
                  gridData[fieldName]= gridData[fieldName]===null || gridData[fieldName]===undefined ? '' : (gridData[fieldName]).toString()
                }
                if(fieldData.type==="List"){
                  const fieldName = fieldData.fieldName
                  const valuesArr = fieldData.Values
                  for (let indexV = 0; indexV < valuesArr.length; indexV++) {
                    const valueElement = valuesArr[indexV];    
                    if(valueElement.key===gridData[fieldName]) {
                      gridData[fieldName]=valueElement.value
                    }       
                  }
                }
                if(fieldData.type==="Yes/No"){
                  const fieldName = fieldData.fieldName
                  if(gridData[fieldName]==="N"){
                    gridData[fieldName]="No"
                  }else{
                    gridData[fieldName]="Yes"
                  }
                }
                if(fieldData.type==="WYSIWYG Editor"){
                  const fieldName = fieldData.fieldName
                  gridData[fieldName] = <div dangerouslySetInnerHTML={{ __html: gridData[fieldName] }}></div>
                }
              }
            }
            
            // console.log("===gridData===",gridData)
  
            const keysArray = Object.keys(reportsGridArray[0]);         
            for (let i = 0; i < keysArray.length; i += 1) {
              for (let index = 0; index < drillDownFields.length; index += 1) {             
                if (keysArray[i] === drillDownFields[index].fieldName) {
                  finalArrayToPush.push(drillDownFields[index]);                  
                  // summaryFieldsArr.push(drillDownFields[index].fieldName);                  
                  break;
                }              
              }
            }
            // console.log("=========finalArrayToPush in Drilldown=========",finalArrayToPush)
            // const keysArray = Object.keys(reportsGridArray[0]);
            for (let index = 0; index < finalArrayToPush.length; index+=1) {
              const element = finalArrayToPush[index];  
              /* for (let index = 0; index < drillDownFields.length; index++) {
                const element = drillDownFields[index];
              } */
              // summaryFieldsForDrillDown.push(element.fieldName)
              headerArrayForDrillDown.push({
                title: <div className="dragHandler" style={{textAlign:finalArrayToPush[index].type==="Numeric"?"right":"left"}}>{element.displayName}</div>,
                dataIndex: element.fieldName,
                type: element.type,
                // key: index,
                render: (text) => finalArrayToPush[index].drillDown==="Y"?<a>{text}</a>:<div style={{textAlign:finalArrayToPush[index].type==="Numeric"?"right":"left"}}>{finalArrayToPush[index].type==="Numeric"?text===undefined || text===null ?'':text.toLocaleString('en-US'):text}</div>,
                onCell: (record) => ({
                  onClick: () => {
                    drillDown(jsonToSend,finalArrayToPush[index].fieldName,record[finalArrayToPush[index].fieldName],finalArrayToPush[index].detailReportId)
                  },
                }),
                width: finalArrayToPush[index].gridlength===undefined || finalArrayToPush[index].gridlength===null ? 100 : parseInt(finalArrayToPush[index].gridlength),
                ellipsis: true,
              })          
            }
            // console.log("=====headerArrayForDrillDown======",headerArrayForDrillDown)
            setHeaderForDrillDown(headerArrayForDrillDown)
            setDataForDrillDown(reportsGridArray)
            setDrillDownTitle(record)
            setPopupForDrillDown(true)
            // setSummaryFieldsForDrillDown(summaryFieldsForDrillDown)

          }else{
            message.error("No data present")
            setLoading(false);
            setLoadingForModal(false)
          }
          }else if(reportResponse.title==="Error"){
            message.error(reportResponse.message)
            setLoadingForModal(false)
            setLoading(false)
          }
        }
      }else{
        // console.log("=======>Drill Down Id<=====",drillDownId)
        /* setHeaderForDrillDown([])
        setDataForDrillDown([])
        setDrillDownTitle(record) */
      }
      
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  }

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      result[key] = data.get(key);
      return result;
    }, {});
  };

  /**
   * @description:-downloadData() downloads the data in CSV file.
   * @author:-Nikhil Gankar
   * @version 1.0
   * @since 31-08-2020
   */

  const downloadData = async () => {
    try {     
      setLoading(true)
      const inputJson = form.getFieldsValue(true)
      // console.log("====inputJson===",inputJson)
      let flagValue = ''
      if (checkedValue === 1) {
        flagValue = "Y";
      } else {
        flagValue = "N";
      }
      // const newData = {}
      Object.entries(inputJson).map(([key, value]) => {
        // console.log("==key==",key,"==value==",value)
        /* if (inputJson) {
          console.log("=========in day js")
          inputJson[key] = `${value.format("YYYY-MM-DD HH:mm:ss")}`;
        } */
        if(value["_isAMomentObject"]){
          // const stringValue = `${value}`
          // console.log("====in if===")
          inputJson[key] = moment(value)
          // inputJson[key] = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
        }
        if (value === true) {
          inputJson[key] = "Y";
        }
        if (value === false) {
          inputJson[key] = "N";
        }
        // inputJson[key] = `${value.format("YYYY-MM-DD")}`;
        /* if (dayjs.isDayjs(value)) {
          console.log("====in dayjs===")
          
        } */
    })

    // console.log("====inputJson===",inputJson)

      // make input data to send to server
      /* for (let index = 0; index < reportFilters.length; index += 1) {
        // formDate
        inputJson["From_Date"] === undefined ? null : new Date(inputJson["From_Date"]).toISOString();
        // formDate
        inputJson["To_Date"] === undefined ? null : new Date(inputJson["To_Date"]).toISOString();
        // flag
        // if (inputJson["flag"] !== undefined) {
        //   inputJson["flag"] === true ? "Y" : "N"
        // }
      } */

      const jsonToSend = JSON.stringify(inputJson).replace(/"/g, '\\"')
      // console.log("=======jsonToSend",jsonToSend)

      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const downloadMutation = {
        query: `query {
        downloadReport(reportId:"${reportId}", reportParam:"${jsonToSend}"){data, messageCode, title, message}
      }`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const response = await Axios.post(genericUrl, downloadMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const responseFromServer = response.data.data.downloadReport
      if(responseFromServer.title==="Success"){
        const fileName = responseFromServer.data
        downloadFile(fileName)
        setLoading(false)
      }else{
        message.error(responseFromServer.message)
        setLoading(false)
      }
      
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  };

  const downloadFile = (fileName)=>{
    try {
      Axios({
        url: `${fileDownloadUrl}`.concat(`${fileName}`),
        method: 'GET',
        responseType: 'blob',
        /* async:true,
        crossDomain: true,
        headers: { 
          'Content-Type': 'text/csv',
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'*',
        } */
      }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.setAttribute('id', 'downloadlink')
        link.href = fileURL
        link.setAttribute('download', `${fileName}`)
        link.click()
      })
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="view">
        <span>View</span>
      </Menu.Item>
      <Menu.Item key="download">
        <span>Download</span>
      </Menu.Item>
    </Menu>
  );

  const handleOk=()=>{
    setPopupForDrillDown(false)
  }

  const handleCancel=()=>{
    setPopupForDrillDown(false)
  }

 const onCheckBoxValueChange = e => {
    if (e.target.checked === true) {
      setCheckedValue(1)
    } else {
      setCheckedValue(0)
    }
  }

  const onSwitchChange = () => {
    if(switchValue===true){
      setSwitchValue(false)
    }else{
      setSwitchValue(true)
    }
    

  }

  const setPivotState=(data)=>{
    setPivotData(data)
  }

  const executeData=()=>{
    form.submit();
  }

  const downloadDrillDownData=async ()=>{
    // console.log("====inside drill down download====",reportParameters)
    try {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const downloadMutation = {
        query: `query {
        downloadReport(parentReportId:"${reportId}",reportId:"${drillDownId}", reportParam:"${reportParameters}"){data, messageCode, title, message}
      }`,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const response = await Axios.post(genericUrl, downloadMutation, { headers: headers }, { async: true }, { crossDomain: true });
      const responseFromServer = response.data.data.downloadReport
      if(responseFromServer.title==="Success"){
        const fileName = responseFromServer.data
        downloadFile(fileName)
        // setLoading(false)
      }else{
        message.error(responseFromServer.message)
        // setLoading(false)
      }
    } catch (error) {
      const { message } = JSON.parse(JSON.stringify(error));
      if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
        localStorage.clear();
        window.location.replace("/login");
      } else {
        return Promise.reject(error);
      }
    }
    
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loading}>
      <Row>
        <Col span={12}>
          {/* <span style={{color:"blue"}}>Reports</span> */}
          <h2 style={{ fontWeight: "600" }}>{reportName}</h2>
        </Col>
        <Col span={7} />
        {/* <Col span={6} />
        <Col span={1}><span style={{float:"right"}}>Download</span></Col>
        <Col span={1}><span style={{float:"right"}}>Switch</span></Col> */}
        <Col span={5}>
          <span style={{ float: "right" }}>
            {showSwitchAndExportButton === true ? (
              <>
                <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                  <img style={{ paddingBottom: "5px", paddingLeft: "1px", width: "18px", cursor: "pointer" }} src={Export} alt="invoice" onClick={downloadData} />
                </Button>
                &nbsp;
                <Button color="primary" style={Themes.contentWindow.ListWindowHeader.listActionButtons}>
                  <img style={{ paddingBottom: "5px", paddingLeft: "1px", cursor: "pointer" }} onClick={onSwitchChange} src={switchPivot} alt="invoice" />
                </Button>
                &nbsp;
              </>
            ) : (
              ""
            )}
            <Button style={Themes.contentWindow.ListWindowHeader.newButtonForlist} type="primary" onClick={executeData}>
              Run
            </Button>
          </span>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={24} style={{ marginBottom: "8px" }}>
          <Row gutter={8}>
            <Col span={20}>
              <Row gutter={8}>
                {reportFilters !== undefined
                  ? reportFilters.map((filtersData, index) => {
                      return (
                        <Col span={6} key={index} style={{ display: filtersData.isForPrompting === "Y" ? "block" : "none" }}>
                          <Form preserve={false} form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item
                              label={filtersData.displayName}
                              name={filtersData.columnName}
                              rules={[{ required: filtersData.mandatory === "Y" ? true : false, message: `Please Enter ${filtersData.displayName}` }]}
                            >
                              {filtersData.type === "MultiSelector" ? (
                                <Select
                                  style={{ width: "100%" }}
                                  onSearch={searchDropdownRecords}
                                  mode="multiple"
                                  maxTagCount={1}
                                  showSearch
                                  allowClear
                                  // loading
                                  // notFoundContent={fetching ? <Spin size="small" /> : null}
                                  dropdownMatchSelectWidth={false}
                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onFocus={() => onDropDownSelect(filtersData.id)}
                                >
                                  {/* {dropdownList} */}
                                  {/* <Spin indicator={<LoadingOutlined style={{ fontSize: "20px" }} spin />} spinning={fetching}> */}
                                  {dropdownDetails === null || dropdownDetails === undefined
                                    ? null
                                    : dropdownDetails.map((data) => {
                                        // console.log("===>data<====", data);
                                        return (
                                          <Option key={data.recordid} value={data.recordid}>
                                            {data.name}
                                          </Option>
                                        );
                                      })}
                                  {/* </Spin> */}
                                </Select>
                              ) : filtersData.type === "DateRange" ? (
                                <RangePicker format={dateFormat} />
                              ) : filtersData.type === "List" ? (
                                <Select
                                  showSearch
                                  allowClear
                                  // notFoundContent={fetching ? <Spin size="small" /> : null}
                                  dropdownMatchSelectWidth={false}
                                  style={{ width: "100%" }}
                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onFocus={() => onDropDownSelectForListReference(filtersData.Values)}
                                >
                                  {referenceListDetails === null || referenceListDetails === undefined
                                    ? null
                                    : referenceListDetails.map((data) => {
                                        // console.log("===>data<====", data);
                                        return (
                                          <Option key={data.key} title={data.key} value={data.key}>
                                            {data.value}
                                          </Option>
                                        );
                                      })}
                                </Select>
                              ) : filtersData.type === "Selector" ? (
                                <Select
                                  showSearch
                                  allowClear
                                  // notFoundContent={fetching ? <Spin size="small" /> : null}
                                  dropdownMatchSelectWidth={false}
                                  style={{ width: "100%" }}
                                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                  onFocus={() => onDropDownSelect(filtersData.id)}
                                >
                                  {dropdownDetails === null || dropdownDetails === undefined
                                    ? null
                                    : dropdownDetails.map((data) => {
                                        // console.log("===>data<====", data);
                                        return (
                                          <Option key={data.recordid} value={data.recordid}>
                                            {data.name}
                                          </Option>
                                        );
                                      })}
                                </Select>
                              ) : filtersData.type === "Date" ? (
                                <DatePicker style={{ width: "100%" }} format={dateFormat} />
                              ) : filtersData.type === "Flag" ? (
                                <Checkbox checked={checkedValue} onChange={onCheckBoxValueChange} />
                              ) : (
                                ""
                              )}
                            </Form.Item>
                          </Form>
                          <br />
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </Col>
            {/* <Col span={4}>
                <Button style={{ float: "right", marginTop: "12%", backgroundColor: "#4eb7a9" }} type="primary" onClick={executeData}>
                  Run
                </Button>
              </Col> */}
          </Row>
        </Col>
      </Row>
      <Row>
        {switchValue === false ? (
          <Col span={24} style={{ marginBottom: "8px" }}>
            {showGridData === true ? (
              <Card bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <TableForReport columnsData={headerData} gridData={gridData} reportFields={reportFields} />
              </Card>
            ) : (
              ""
            )}
          </Col>
        ) : (
          <Col span={24} style={{ marginBottom: "8px" }}>
            <Card style={{ margin: "14px", width: "1070px", height: "425px", overflow: "auto" }}>
              <PivotTableUI data={gridData} onChange={(s) => setPivotState(s)} renderers={Object.assign({}, TableRenderers, PlotlyRenderers)} {...pivotData} />
            </Card>
          </Col>
        )}
      </Row>
      <div>
        <Modal
          id="reportAntModal"
          bodyStyle={{ backgroundColor: "#F2F3F6",paddingBottom:"8px" }}
          className="ant-modal-header-custom"
          title={
            <Spin indicator={<LoadingOutlined style={{ fontSize: "10px" }} spin />} spinning={loadingForModal}>
              <Row>
                <Col span={8} />
                <Col span={8}>
                  {popUpReportName} - {drillDownTitle}
                </Col>
                <Col span={8}>
                  <Button
                    color="primary"
                    style={{ float: "right", height: "32px", width: "33px", paddingLeft: "5px", paddingRight: "5px", paddingTop: "6px", borderRadius: "5px" }}
                  >
                    <img style={{ paddingBottom: "5px", paddingLeft: "1px", width: "18px", cursor: "pointer" }} src={Export} alt="invoice" onClick={downloadDrillDownData} />
                  </Button>
                </Col>
              </Row>
            </Spin>
          }
          visible={drillDownPopup}
          // onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          centered
          width="1000px"
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: "52px" }} spin />} spinning={loadingForModal}>
            <Card bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <TableForReport columnsData={drillDownHeader} gridData={drillDownData} reportFields={reportFields} />
            </Card>
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};

export default Report;