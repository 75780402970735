import React, { useState } from "react";
import { Modal } from "antd";
import Axios from "axios";
import { genericUrl } from "../../../constants/serverConfig";
import TableForChart from "./TableForChart";

const TableChartComponent = (props) => {
  /* const amountFormat = (badgeValue) => {
    let result;
    if (badgeValue === undefined) {
      result = 0;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e9) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e9).toFixed(2)} B`;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e6) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e6).toFixed(2)} M`;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e3) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e3).toFixed(2)} K`;
    } else {
      result = Math.abs(Number(badgeValue));
    }
    return result;
  }; */
  const [drillDownPopup, setPopupForDrillDown] = useState(false);
  const [drillDownTableColumns, setDrillDownTableColumns] = useState([]);
  const [drillDownTableData, setDrillDownTableData] = useState([]);
  const { tableChartData, tableKpi, dashboardParamsAndKpiData,fullScreenValue } = props;
  const tableProperties = JSON.parse(tableKpi.properties);
  const columnsDataArr = tableProperties.columnsData;
  for (let index = 0; index < columnsDataArr.length; index++) {
    const element = columnsDataArr[index];
    const title = element.title;
    if (element.drillDown === "Y") {
      element["render"] = (text) => <span style={{ color: "blue" }}>{text}</span>;
      element["onCell"] = (record) => ({
        onClick: () => {
          drillDownForTable(record, tableKpi);
        },
      });
    }
    // console.log("===>element<====",element)
    element["width"] = 100;
    element["ellipsis"] = true;
    element.title = <span className="dragHandler"><b style={{color:"gray"}}>{title}</b></span>
    /* if (element.trim === "Y") {
        let titleName = element.title;
        element["render"] = (titleName) => (
            <Tooltip placement="topLeft" title={titleName}>
                {titleName}
            </Tooltip>
        );
        } */
  }
  

  // console.log("==>columnsDataArr<==",columnsDataArr)

  const drillDownForTable = (record, tableKpi) => {
    try {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      const kpiData = dashboardParamsAndKpiData.kpiData;
      const drillDownFilter = tableKpi.drilldown_inputfilters;
      const paramsJson = dashboardParamsAndKpiData.dashboardParams;
      paramsJson[drillDownFilter] = record[drillDownFilter];
      const stringifiedJSON = JSON.stringify(paramsJson);
      const jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const executeDashboardMutation = {
        query: `query {
             executeDashboard(dashboardId:"${tableKpi.nt_dashboard_id}",kpiId:"${tableKpi.drilldown_kpi_id}",dashboardParam:"${jsonToSend}"){data, messageCode, title, message}
           }`,
      };
      Axios.post(genericUrl, executeDashboardMutation, { headers: headers }, { async: true }, { crossDomain: true }).then((execDashRes) => {
        const responseFromServer = execDashRes.data.data.executeDashboard;
        if (responseFromServer.title === "Success") {
          const successResponse = JSON.parse(responseFromServer.data);
          const tableData = successResponse[tableKpi.drilldown_kpi_id];
          // console.log("======tableData========", tableData);
          let chartJSON = {};
          for (let chartIndex = 0; chartIndex < kpiData.length; chartIndex += 1) {
            const element = kpiData[chartIndex];
            if (tableKpi.drilldown_kpi_id === element.kpi_id) {
              chartJSON = element;
            }
          }
          const chartProperties = JSON.parse(chartJSON.properties);
          if (chartJSON.type === "Table Chart") {
            const drillDownTableCols = chartProperties.columnsData;
            // console.log("========>drillDownTableCols<========",drillDownTableCols)
            for (let index = 0; index < drillDownTableCols.length; index++) {
              const element = drillDownTableCols[index];
              const title = element.title;
              element.title = <span className="dragHandler">{title}</span>;
              if (element.drillDown === "Y") {
                element["render"] = (text) => <span style={{ color: "blue" }}>{text}</span>;
                element["onCell"] = (record) => ({
                  onClick: () => {
                    drillDownForTable(record, chartJSON);
                  },
                });
              };
              setPopupForDrillDown(true)
              setDrillDownTableData(tableData)
              setDrillDownTableColumns(drillDownTableCols);
              /* if (element.trim === 'Y') {
                  let titleName = element.title
                  element['render'] = titleName => (
                    <Tooltip placement="topLeft" title={titleName}>
                      {titleName}
                    </Tooltip>
                  )
                }
              }
              this.setState({
                clickedIdInState: record[drillDownFilter],
                kpiTitleInState: kpiUI.name,
                drillDownTableColumns: chartProperties.columnsData,
                drillDownTableData: tableData,
                showDrillDownTable: true,
                drillDownPopup:true,
                loading:false,
                loadingForDrillDown:false
              }) */
            }

            /* this.setState({
              tableDataForDrillDown: tableData,
              columnsDataForDrillDown: chartProperties.columnsData,
            }) */
          }
        } else {
          console.log("=======>responseFromServer.message<=======", responseFromServer.message);
          // message.error(responseFromServer.message)
          // this.setState({loading:false,loadingForDrillDown:false,drillDownTableColumns:[],drillDownTableData:[]})
        }
      });
    } catch (error) {
      console.log("==>error for drill down table<==", error);
    }
  };

  const handleOk=()=>{
    setPopupForDrillDown(false)
  }

  const handleCancel=()=>{
    setPopupForDrillDown(false)
  }

  return (
    <div>
      <TableForChart dataSource={tableChartData} columnsData={columnsDataArr} tableKpi={tableKpi} fullScreenValue={fullScreenValue}  />
      {/* <Table sticky={true} pagination={false} dataSource={tableChartData} columns={columnsDataArr} size="small" scroll={{ y: "30vh" }} /> */}
      <Modal title="Drill Down Table" visible={drillDownPopup} onOk={handleOk} onCancel={handleCancel} centered width="1000px">
        <TableForChart dataSource={drillDownTableData} columnsData={drillDownTableColumns} tableKpi={tableKpi} />
        {/* <Table sticky={true} pagination={false} dataSource={drillDownTableData} columns={drillDownTableColumns} size="small" scroll={{ y: "30vh" }} /> */}
      </Modal>
    </div>
  );
};

export default TableChartComponent;
