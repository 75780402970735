import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Table, Input } from "antd";
const EditableContext = React.createContext(null);

const RecordTable = (props) => {
  const { dataSource, columns, selectedRecordsData, setSelectedRecordsData } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectRowChange = (selectedRowKeys) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRowChange,
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      const selRecords = { ...selectedRecordsData };
      selRecords[dataSource[0].tableName] = [...selectedRowKeys];
      setSelectedRecordsData({ ...selRecords });
    }
  }, [selectedRowKeys]);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, rowIndex, ...restProps }) => {
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const toggleEdit = () => {
      
      form.setFieldsValue({
        [dataIndex]: record[dataIndex] ? record[dataIndex] : "",
      });
      if (selectedRowKeys.findIndex((op) => `${rowIndex}` === `${op}`) < 0) {
        setSelectedRowKeys([...selectedRowKeys, `${rowIndex}`]);
      }
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.error("Save failed:", errInfo);
      }
    };

    let childNode = children;
    let actualValue = 0;
    if (dataIndex) {
      actualValue = record[dataIndex] ? `${record[dataIndex]}`: "";
    }

    if (editable) {
      childNode = true ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          initialValue={actualValue}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={null}
        >
          {children}
        </div>
      );
    }

    return <td>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Table rowClassName={() => "editable-row"} dataSource={dataSource} columns={columns} components={components} rowSelection={rowSelection} size="small" pagination={false} />
  );
};

export default RecordTable;
