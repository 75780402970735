/* eslint-disable */
import React,{useState,useEffect} from 'react'
import {Spin,Row,Col,Button,Select,Form,Card,DatePicker,Radio,Input,Tooltip,Tabs,Modal,InputNumber,Table,message,Collapse} from 'antd'
import { LoadingOutlined,CaretRightOutlined,DownloadOutlined } from "@ant-design/icons";
import { ExportToCsv } from 'export-to-csv'
import { getRoleBusinessUnit,getPurchaseOrderDocData,getSupplierProductsList,getPoOrderLineProducts,getDeliveryLocation,getLandedCostData} from "../../../services/custom";
import {getGrnSuppliers,getAllSupplier,getDefaultSupplier,getSupplierAddress} from "../../../services/generic"
import {useGlobalContext} from "../../../lib/storage"
import Axios from 'axios'
import {serverUrl,genericUrl,fileDownloadUrl} from '../../../constants/serverConfig'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import MainTable from './MainTable'
import OtherCostTable from './OtherCostTable'
import summaryTableColumns from './summaryCols'
import barcodeImage from "../../../assets/images/barcode.svg";
import "antd/dist/antd.css";
import "../../../styles/antd.css"


const {Option} = Select
const {TabPane} = Tabs
const {Panel} = Collapse

const  GRN = () => {
    const { globalStore } = useGlobalContext();
    const Themes = globalStore.userData.CW360_V2_UI;
    const [form] = Form.useForm();
    const [skuform] = Form.useForm()
    const [headerform] = Form.useForm()
    const [otherCostForm] = Form.useForm()
    const [summaryForm] = Form.useForm()
    const [importform] = Form.useForm()



    const [loading,setLoading] = useState(false)
    const [withOrwithoutPoValue,setwithOrwithoutPoValue] = useState("1")
    const [bunitId,setBunitId] = useState('')
    const [bunitData,setBunitData] = useState([])
    const [businessUnitName,setBusinessUnitName] = useState('')
    const [suppliersValuesInState,setSuppliersValuesInState] = useState([])
    const [withOutPoSuppliersData,setWithOutPoSuppliersData] = useState([])
    const [suppliersValuesInStateCopy,setSuppliersValuesInStateCopy] = useState([])
    const [supplierId,setSupplierId] = useState('')
    const [poDocList,setPoDocList] = useState([])
    const [orderId,setOrderId] = useState('')
    const [istaxincludedFlag,setIstaxincludedFlag] = useState(null)
    const [productData,setProductData] = useState([])
    const [orderDate,setOrderDate] = useState("")
    const [radioValue, setRadioValue] = useState(1);
    const [tabKey,setTabkey] = useState("10")
    const [disableBatchField,setDisableBatchField] = useState(true)
    const [disableDiscountInput,setDisableDiscountInput] = useState("")
    const [selectedGridRecord,setSelectedGridRecord] = useState({})
    const [disabledDiscountType,setDisabledDiscountType] = useState(true)
    const [supplierAddressName,setSupplierAddressName] = useState(null)
    const [supplierAddressId,setSupplierAddressId] = useState(null)
    const [deliveryFullAddress,setDeliveryFullAddress] = useState(null)
    const [otherCostData,setOtherCostData] = useState([])
    const [addCostVisible,setAddCostVisible] = useState(false)
    const [landedCostDropDownData ,setLandedCostDropDownData] = useState([])
    const [selectedLandedCost,setSelectedLandedCost] = useState({})
    const [grnSummaryData,setGrnSummaryData] = useState([])
    const [uniqueId,setUniqueId] = useState('')
    const [summaryModalVisible,setSummaryModalVisible] = useState(false)
    const [batchModalVisible,setBatchModalVisible] = useState(false)
    const [importModalVisible,setImportModalVisible] = useState(false)
    const [importDiscountType,setImportDiscountType] = useState(undefined)

    useEffect(() => {
        let newDate = moment(new Date()).format('YYYY-MM-DD')
        headerform.setFieldsValue({
         'with-withoutpo': "With Po",  
         'date':moment(newDate),
        })
        summaryForm.setFieldsValue({
          'summinvoiceDate':moment(newDate),
          'summreceiptdate':moment(newDate)
        })
        getBusinessUnit()
        getDefaultSupplierList()
    }, [])

    const getBusinessUnit = async () =>{
        const userData = JSON.parse(window.localStorage.getItem('userData'))
        const businessUnitResponse = await getRoleBusinessUnit(userData.user_id)
        headerform.setFieldsValue({
          businessUnit:businessUnitResponse.bUnitName
        })
        summaryForm.setFieldsValue({
          'summbusinessunit':businessUnitResponse.bUnitName
        })
        setBunitId(businessUnitResponse.defaultCsBunitId)
        setBunitData(businessUnitResponse.userBunit)
        setBusinessUnitName(businessUnitResponse.bUnitName)
        getAllSupplierList(businessUnitResponse.defaultCsBunitId)
        // setTimeout(() => {
        //     getPurchaseOrderDocList()
        // }, 200);
      }

    const onFinish = () =>{

    }

    const onSelectOfWithORwithoutPo = (e) =>{
        setwithOrwithoutPoValue(e)
        headerform.resetFields(['supplier'])
        if(e === "2"){
            setSuppliersValuesInState([])
            setSuppliersValuesInState(withOutPoSuppliersData)
        }else{
            setSuppliersValuesInState([])
            setSuppliersValuesInState(suppliersValuesInStateCopy)
        }
    }

    const onSelectBusinessUnit =(e,data) =>{
        setBunitId(e)
        summaryForm.setFieldsValue({
          'summbusinessunit':data.title
        })
        if(withOrwithoutPoValue === "2"){
            getGRNSupplier(e)
        }
        setTimeout(() => {
            getPurchaseOrderDocList()  
        }, 300);
        getAllSupplierList(e)
    }

    const getGRNSupplier = async (e) =>{
        const grnSupplierResponse = await getGrnSuppliers(e)
        setSuppliersValuesInState(grnSupplierResponse)
    }

    const getAllSupplierList = async (e) =>{
        const allSupplierResponse = await getAllSupplier(e)
        setWithOutPoSuppliersData(allSupplierResponse)
    }

    const getDefaultSupplierList = async () =>{
        const defaultSupplierResponse = await getDefaultSupplier()
        setSuppliersValuesInState(defaultSupplierResponse)
        setSuppliersValuesInStateCopy(defaultSupplierResponse)
    }

    const supplierOnchange = (e, data) =>{
        setSupplierId(e)
        setIstaxincludedFlag(data.props.istaxflag)
        summaryForm.setFieldsValue({
          'summsupplier':data.title
        })
        setTimeout(() => {
            // getPurchaseOrderDocList() 
            if(withOrwithoutPoValue === "2"){
                getSupplierProducts(e,data.props.istaxflag)
              }
        }, 200);
        getSupplierAdd(e)
        getDeliveryLoc()
    }

    const getSupplierAdd = async(e) =>{
      const getSupplierAddressResponse = await getSupplierAddress(e)
      setSupplierAddressName(getSupplierAddressResponse[0].name)
      setSupplierAddressId(getSupplierAddressResponse[0].recordid)
      summaryForm.setFieldsValue({
        'summorderdate':null,
        'summsupplierAddress':getSupplierAddressResponse[0].name
       })
    }
    const getDeliveryLoc = async() =>{
      const getDeliveryLocResponse = await getDeliveryLocation(bunitId)
      if(getDeliveryLocResponse.length > 0){
        setDeliveryFullAddress(getDeliveryLocResponse[0].fulladdress)
        summaryForm.setFieldsValue({
          'summdeliveryAddress':getDeliveryLocResponse[0].fulladdress
        })
      }else{
        setDeliveryFullAddress(null)
        summaryForm.setFieldsValue({
          'summdeliveryAddress':null
        })
      }
    }

    const getPurchaseOrderDocList = async () =>{
        // setLoading(true)
        const purchaseOrderDocResponse = await getPurchaseOrderDocData(bunitId,supplierId)
        if(purchaseOrderDocResponse.length > 0){
            for (let index = 0; index < purchaseOrderDocResponse.length; index++) {
                purchaseOrderDocResponse[index].docToshow = purchaseOrderDocResponse[index].docNo +"-" + moment(purchaseOrderDocResponse[index].dateOrdered).format('DD/MM/YYYY') +"-" + purchaseOrderDocResponse[index].totalGrossAmount +"/-" ;
            }
            setPoDocList(purchaseOrderDocResponse)
            // setLoading(false)
        }
    }

    const onSelectPurchaseOrder = async (e,data) =>{
        setOrderId(e)
        setOrderDate(moment(data.orderdate).format('YYYY-MM-DD'))
        getpOrderData(e)
        let orderDate
        if(data.orderdate === null || data.orderdate === undefined || data.orderdate === ""){
          orderDate = null
          summaryForm.setFieldsValue({
            'summorderdate':null,
            'poDoc':data.title
           })
        }else{
          orderDate = moment(data.orderdate).format('YYYY-MM-DD')
          summaryForm.setFieldsValue({
            'summorderdate':orderDate,
            'poDoc':data.title
           })
        }       
    }

    const getpOrderData = async (e) =>{
     const poProductResponse =  await getPoOrderLineProducts(e,bunitId,supplierId)
     const productList = poProductResponse
     for (let index = 0; index < productList.length; index += 1) {
        productList[index].key = productList[index].productId
        productList[index].receivingQty = undefined
        productList[index].priceList = productList[index].listPrice
        productList[index].priceStd = productList[index].unitPrice
        productList[index].priceStd1 = productList[index].unitPrice
        productList[index].priceStd2 = productList[index].unitPrice
        productList[index].totalDiscount = productList[index].totaldiscount
        productList[index].poTotalDiscount = productList[index].totaldiscount
        productList[index].isPoTotalDiscoutValue = productList[index].discounttype === 'TV' ? "Total value Discount" : productList[index].discounttype === "P" ? "Percentage" : productList[index].discounttype === 'Value' ? "Value" : null
        productList[index].DiscountType = productList[index].discounttype === 'TV' ? "Total value Discount" : productList[index].discounttype === "P" ? "Percentage" : productList[index].discounttype === 'Value' ? "Value" : null
        productList[index].freeqty = undefined
        productList[index].value = productList[index].productSearchKey
        productList[index].taxId = productList[index].taxRate.csTaxID
        productList[index].taxName = productList[index].taxRate.name
        productList[index].taxRate = productList[index].taxRate.rate
        productList[index].margin = (((productList[index].listPrice - productList[index].unitPrice) / (productList[index].listPrice)) * 100).toFixed(2)
      }
      setProductData(productList)
    }

    const getSupplierProducts = async (e,flag) =>{
        const supplierProductResponse = await getSupplierProductsList(e)
        if (supplierProductResponse.length > 0) {
            const data = supplierProductResponse
            let netUnitPrice;
            for (let index = 0; index < data.length; index++) {
              let uniqueId = uuid()
            .replace(/-/g, '')
            .toUpperCase()
              data[index].uniqueId = uniqueId
              data[index].key = uniqueId
              data[index].priceStd1 = data[index].priceStd
              data[index].priceStd2 = data[index].priceStd
              data[index].margin = (((data[index].priceList - data[index].priceStd) / data[index].priceList) * 100).toFixed(2)
              data[index].oldMargin = (((data[index].priceList - data[index].priceStd) / data[index].priceList) * 100).toFixed(2)
              data[index].value  = data[index].productCode
              data[index].productSearchKey = data[index].productCode
              let istaxFlag = flag
              if(istaxFlag === 'Y'){
                netUnitPrice = (data[index].priceStd/(1+data[index].taxRate/100)).toFixed(2)
               }else{
                const netUnitPrice1 = (data[index].priceStd/(1+data[index].taxRate/100))
                const taxOnUnitPrice1 = ((data[index].taxRate/ 100) * netUnitPrice1)
                netUnitPrice = parseFloat(data[index].priceStd) + parseFloat(taxOnUnitPrice1)
               }
               data[index].netUnitPrice = netUnitPrice
            }
           setProductData(data)
          }
    }

    const onChangeRadio = (e) =>{
        setRadioValue(e.target.value)
    }

    const grnSummaryReview = () =>{
    let tempArray = []
    for (let index = 0; index < productData.length; index++) {
      const element = parseFloat(productData[index].receivingQty);
      const ele2 = parseFloat(productData[index].freeqty);
        if (element > 0 || ele2 > 0) {
        tempArray.push(productData[index])
      }
    }
    if(tempArray.length === 0){
      message.error("Please Add products")
    }else{
      callbackTabs()
      setSummaryModalVisible(true)
      // this.setState({GoodsSummaryvisible:true})
    }
    }

    const callbackTabs = (e) =>{
        setTabkey(e)
        form.resetFields()
        setSelectedGridRecord({})
        if(supplierId === null || supplierId === undefined || supplierId === ''){
          message.error("Please select supplier")
        }else{
          let tempArray = []
          for (let index = 0; index < productData.length; index++) {
            const element = productData[index].receivingQty;
            const ele2 = productData[index].freeqty;
            const basePrice = productData[index].priceStd
            const netUnitPrice = (basePrice/(1+productData[index].taxRate/100))
            const gridGrossAmt = productData[index].gridGross
            let totalTax;
            if(istaxincludedFlag === 'Y'){
               totalTax = ((gridGrossAmt) - (netUnitPrice * element))
            }else{
              totalTax = ((gridGrossAmt) - (netUnitPrice * element))
            }
            productData[index].totalTax = (totalTax).toFixed(2)
            productData[index].totalDiscount = productData[index].totalDiscount === undefined || productData[index].totalDiscount === null || productData[index].totalDiscount === '' ? 0 : productData[index].totalDiscount
      
            if (element > 0 || ele2 > 0) {
              tempArray.push(productData[index])
            }
          }
          for (let index3 = 0; index3 < tempArray.length; index3 += 1) {
            tempArray[index3].receivingQty === undefined ? 0 : tempArray[index3].receivingQty
          }
          let flag;
          for (let index6 = 0; index6 < tempArray.length; index6 += 1) {
            const orderQtyelement = parseFloat(tempArray[index6].orderedQty);
            const receivingQtyelement = parseFloat(tempArray[index6].receivingQty);
            if (receivingQtyelement > orderQtyelement) {
              flag = false
            } else {
            }
          }
          if (flag === false) {
            message.error('Total Receiving qty shouldn’t be greater than Order qty')
          } else {
            setGrnSummaryData(tempArray)
            const uniqId = uuid()
              .replace(/-/g, '')
              .toUpperCase()
            setUniqueId(uniqId)
          }
        }
    }
   
    const getSelectedRecord = (data) =>{
      let disableBatch;
      if(withOrwithoutPoValue === '1'){
        if(data.batchedProduct === "Y"){
          disableBatch=false
        }else{
          disableBatch=true
        }
        form.setFieldsValue({
          Value:data.value,
          Name:data.productName,
          UOM:data.uomName,
          receivingQty:data.receivingQty,
          FreeQty:data.freeqty,
          orderedQty:data.orderedQty,
          poFreeQty:data.poFreeQty,
          receivedQty:data.receivedqty,
          DiscountType:data.DiscountType,
          discountValue:data.discountvalue,
          BasePriceChange:data.priceStd1,
          unitPrice:data.priceStd,
          netUnitPrice:data.netUnitPrice,
          multiplePopMRP:data.priceList,
          margin:data.margin,
          OldMargin:data.oldMargin,
          GrossAmount:data.gridGross,
          totalDiscount:data.totalDiscount,
          batchNo:data.batchNo,
          mfg_date:data.mfg_date === undefined || data.mfg_date === null ? null :moment(data.mfg_date),
          expiry_date : data.expiry_date === undefined || data.expiry_date === null ? null :moment(data.expiry_date),
          description:data.description,
          netAmount:data.lineNetGross
        })
        const disabledDiscountType1 = data.isPoTotalDiscoutValue === null ? false :true
        setSelectedGridRecord(data)
        setDisabledDiscountType(disabledDiscountType1)
        setDisableBatchField(disableBatch)
      }else{
        let disableBatch;
          if(data.batchedProduct === 'Y'){
            disableBatch=false
          }else{
            disableBatch=true
          }
          const disabledFlagg = data.DiscountType === '' || data.DiscountType === null || data.DiscountType === undefined ? false :true
          const  margin = (((data.priceList - data.priceStd) / data.priceList) * 100).toFixed(2)
        data.margin = margin
        const  margin1 = (((data.priceList - data.priceStd1) / data.priceList) * 100).toFixed(2)
        data.oldMargin = margin1
        form.setFieldsValue({
          Value:data.value,
          Name:data.productName,
          UOM:data.uomName,
          receivingQty:data.receivingQty,
          FreeQty:data.freeqty,
          orderedQty:data.orderedQty,
          poFreeQty:data.poFreeQty,
          receivedQty:data.receivedqty,
          DiscountType:data.DiscountType,
          discountValue:data.discountvalue,
          BasePriceChange:data.priceStd1,
          unitPrice:data.priceStd,
          netUnitPrice:data.netUnitPrice,
          multiplePopMRP:data.priceList,
          margin:margin,
          OldMargin:data.oldMargin,
          GrossAmount:data.gridGross,
          totalDiscount:data.totalDiscount,
          batchNo:data.batchNo,
          mfg_date:data.mfg_date === undefined || data.mfg_date === null ? null :moment(data.mfg_date),
          expiry_date : data.expiry_date === undefined || data.expiry_date === null ? null :moment(data.expiry_date),
          description:data.description,
          netAmount:data.lineNetGross
        })
          setSelectedGridRecord(data)
          setDisableDiscountInput(disabledFlagg)
          setDisableBatchField(disableBatch)
      }
    }

    const OnChangeOrderQty = () =>{
      if(withOrwithoutPoValue === "1"){
        setTimeout(() => {
          const formFieldsData = form.getFieldsValue(true);
              let receivingQty = formFieldsData.receivingQty
              let batchNo = formFieldsData.batchNo
              let mfg_date= formFieldsData.mfg_date ==='' || formFieldsData.mfg_date === undefined || formFieldsData.mfg_date === null?null:formFieldsData.mfg_date
              let expiry_date = formFieldsData.expiry_date === '' || formFieldsData.expiry_date === undefined || formFieldsData.expiry_date === null ?null:formFieldsData.expiry_date
              let totalPoOrderedQty = selectedGridRecord.orderedQty
              let freeQty = formFieldsData.FreeQty
              let netUnitPrice;
              let taxOnUnitPrice;
              let totalTax;
              let priceStd = selectedGridRecord.priceStd
              let gridGross;
              let taxRate = selectedGridRecord.taxRate
              let totalDiscount;
              let lineNetGross;
              if(receivingQty > totalPoOrderedQty){
                message.error("Receivig qty should not more than ordered qty")
                form.setFieldsValue({'receivingQty':0})
              }else{
                if(selectedGridRecord.isPoTotalDiscoutValue === "Total value Discount"){
                  setDisableDiscountInput(false)
                  const totalPoDiscount = selectedGridRecord.poTotalDiscount
                  const avgDiscount = totalPoDiscount/totalPoOrderedQty
                  selectedGridRecord.discountvalue = (avgDiscount) * (receivingQty)
                  totalDiscount = (avgDiscount) * (receivingQty)
                  selectedGridRecord.totalDiscount = totalDiscount
                     if(istaxincludedFlag === 'Y'){
                    netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                    taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                    totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                    gridGross = (priceStd * receivingQty).toFixed(2)
                    lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                   }else{
                    const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                    taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                    netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                    totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                    gridGross = (priceStd * receivingQty).toFixed(2)
                    lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                   }
                  }
                  else if(selectedGridRecord.isPoTotalDiscoutValue === "Percentage"){
                    setDisableDiscountInput(false)
                    const totalPoDiscount = selectedGridRecord.poTotalDiscount
                    const avgDiscount = totalPoDiscount/totalPoOrderedQty
                    totalDiscount = (avgDiscount) * (receivingQty)
                    selectedGridRecord.totalDiscount = totalDiscount
                    if(istaxincludedFlag === 'Y'){
                      netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }else{
                      const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                      netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }
                  }else if(selectedGridRecord.isPoTotalDiscoutValue === "Value"){
                    setDisableDiscountInput(false)
                    selectedGridRecord.discountvalue = selectedGridRecord.discountvalue
                    selectedGridRecord.totalDiscount = (selectedGridRecord.discountvalue) * (receivingQty)
                    totalDiscount = (selectedGridRecord.discountvalue) * (receivingQty)
                    if(istaxincludedFlag === 'Y'){
                      netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }else{
                      const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                      netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }
                  }else{
                    setDisableDiscountInput(true)
                    getWithoutPoCalculations()
                  }
                  form.setFieldsValue({
                    'GrossAmount': gridGross,
                    'netUnitPrice':netUnitPrice,
                    'netAmount':lineNetGross
                  })

                  const Obj = {
                    'description': "",
                    'DiscountType':selectedGridRecord.DiscountType,
                    'discountvalue': selectedGridRecord.discountvalue,
                    'totalDiscount': totalDiscount,
                    'documentNo': selectedGridRecord.documentNo,
                    'freeqty': freeQty === undefined || freeQty === null ? 0 :freeQty,
                    'gridGross': gridGross,
                    'line': selectedGridRecord.line,
                    'listPrice': selectedGridRecord.listPrice,
                    'margin': selectedGridRecord.margin,
                    'orderId':selectedGridRecord.orderId,
                    'orderLineId': selectedGridRecord.orderLineId,
                    'orderedQty': selectedGridRecord.orderedQty,
                    'pofreeqty':selectedGridRecord.pofreeqty,
                    'priceList': selectedGridRecord.priceList,
                    'priceStd': selectedGridRecord.priceStd,
                    'priceStd1': selectedGridRecord.priceStd,
                    'productId':selectedGridRecord.productId,
                    'productName':selectedGridRecord.productName,
                    'productSearchKey':selectedGridRecord.productSearchKey,
                    'receivedqty':selectedGridRecord.receivedqty,
                    'receivingQty':receivingQty,
                    'stockQty': selectedGridRecord.stockQty,
                    'stockUomId': selectedGridRecord.stockUomId,
                    'stockUomIdName':selectedGridRecord.stockUomIdName,
                    'unitPrice':selectedGridRecord.unitPrice,
                    'uomId': selectedGridRecord.uomId,
                    'uomName':selectedGridRecord.uomName,
                    'value':selectedGridRecord.value,
                    'netUnitPrice':netUnitPrice,
                    'totalTax':totalTax,
                    'taxId' :selectedGridRecord.taxId,
                    'taxName' : selectedGridRecord.taxName,
                    'taxRate' : selectedGridRecord.taxRate,
                    'isPoTotalDiscoutValue':selectedGridRecord.isPoTotalDiscoutValue,
                    'poTotalDiscount':selectedGridRecord.poTotalDiscount,
                    'batchedProduct':selectedGridRecord.batchedProduct,
                    'batchNo':batchNo,
                    'lineNetGross':lineNetGross,
                    'expiry_date':moment(expiry_date).format('YYYY-MM-DD')==="Invalid date"?null:moment(expiry_date).format('YYYY-MM-DD'),
                    'mfg_date':moment(mfg_date).format('YYYY-MM-DD')==="Invalid date"?null:moment(mfg_date).format('YYYY-MM-DD'),
                  }
                  const newArray = []
              for (let index = 0; index < productData.length; index++) {
                const productIdFromArray = productData[index].productId;
                if(productIdFromArray !== selectedGridRecord.productId){
                  newArray.push(productData[index])
                }
              }
              newArray.unshift(Obj)
              setProductData(newArray)
              }
        }, 300);
      }else{
        getWithoutPoCalculations()
      } 
    }

  const  getWithoutPoCalculations = () =>{
      setTimeout(() => {
              const formFieldsData = form.getFieldsValue(true);
              let batchNo = formFieldsData.batchNo
              let mfg_date=formFieldsData.mfg_date===''?null:formFieldsData.mfg_date
              let expiry_date = formFieldsData.expiry_date === '' ?null:formFieldsData.expiry_date
              const discountType = formFieldsData.DiscountType
              let orQty = (formFieldsData.receivingQty === null || formFieldsData.receivingQty === undefined  ? 0:formFieldsData.receivingQty) 
              let basePrice = formFieldsData.BasePriceChange
              let freeqty = formFieldsData.FreeQty
              let margin;
              let gridGrossAmt; 
              let totalDiscount = 0;
              let unitPrice;
              let netUnitPrice;
              let totalTax;
              let price;
              let lineNetGross;
              let dprice = (formFieldsData.discountValue === null || formFieldsData.discountValue === undefined ? 0 :formFieldsData.discountValue )
              let d3 = dprice/orQty
              let d4 = isNaN(d3)
              let initialPriceList = selectedGridRecord.priceList
              if(d4 === true){
                price= 0
              }else{
                price = d3
              }              
              if(discountType === undefined || discountType === null){
                setDisableDiscountInput(false)
                  if(istaxincludedFlag === 'Y'){
                   const netUnitPrice1 = ((basePrice/(1+selectedGridRecord.taxRate/100)) - price)
                   const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                   const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                   const gridGrossAmt1 = unitPrice1 * orQty
                   const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                   const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                    lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
  
                  }else{
                   const netUnitPrice1 = (basePrice - price).toFixed(2)
                   const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                   const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                   const gridGrossAmt1 = unitPrice1 * orQty
                   const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                   const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                    netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                    unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                    gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                    margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                    totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                    lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
                  }
              }else if(discountType === "Total value Discount"){
                setDisableDiscountInput(true)
                if(istaxincludedFlag === 'Y'){
                 const netUnitPrice1 = ((basePrice/(1+selectedGridRecord.taxRate/100)) - price)
                 const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                 const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
                 const gridGrossAmt1 = (unitPrice1 * orQty)
                 const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                 const basePriceAfterTax = (basePrice/(1+selectedGridRecord.taxRate/100))
                 const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
                 const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
  
                }else{
                 const netUnitPrice1 = (basePrice - price)
                  const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                 const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                 const gridGrossAmt1 = (unitPrice1 * orQty)
                 const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                 const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
                 const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
                }
              }else if(discountType === "Percentage"){
                setDisableDiscountInput(true)
                if(istaxincludedFlag === 'Y'){
                  const costPrice3 = (basePrice/(1+(selectedGridRecord.taxRate/100)))
                  const discountAmount = ((dprice / 100) * costPrice3)
                  
                  const netUnitPrice1 = ((basePrice/(1+selectedGridRecord.taxRate/100)) - discountAmount)
                  const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                  const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                  const gridGrossAmt1 = (unitPrice1 * orQty)
                  const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                  const basePriceAfterTax = (basePrice/(1+selectedGridRecord.taxRate/100))
                  const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
                  const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
                }else{
                const discountAmount = ((dprice / 100) * basePrice)
                const netUnitPrice1 = (basePrice - discountAmount)
                const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
                const gridGrossAmt1 = (unitPrice1 * orQty)
                const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
                const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
  
                }
              }else if(discountType === "Value"){
                setDisableDiscountInput(true)
                if(istaxincludedFlag === 'Y'){
                 const netUnitPrice1 = ((basePrice/(1+selectedGridRecord.taxRate/100)) - dprice)
                 const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                 const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                 const gridGrossAmt1 = unitPrice1 * orQty
                 const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                 const basePriceAfterTax = (basePrice/(1+selectedGridRecord.taxRate/100))
                 const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
                 const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
  
                }else{
                 const netUnitPrice1 = (basePrice - dprice)
                  const taxOnUnitPrice = ((selectedGridRecord.taxRate/ 100) * netUnitPrice1)
                 const unitPrice1 = parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice)
                 const gridGrossAmt1 = unitPrice1 * orQty
                 const margin1 = (((initialPriceList - unitPrice1) / initialPriceList) * 100)
                 const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
                 const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
                  netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
                  unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
                  gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
                  margin = (Math.round(margin1 * 100) / 100).toFixed(2)
                  totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
                  totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
                  lineNetGross = (netUnitPrice1 * orQty).toFixed(2)
  
                }
  
              }
              form.setFieldsValue({
                'GrossAmount':gridGrossAmt,
                'unitPrice':unitPrice,
                'netUnitPrice':netUnitPrice,
                'margin':margin,
                'totalDiscount':totalDiscount,
                'netAmount':lineNetGross
              })
  
              const Obj = {
                'DiscountType':discountType,
                'description': "",
                'discountvalue': dprice,
                'totalDiscount': totalDiscount,
                'documentNo': selectedGridRecord.documentNo,
                'freeqty': freeqty === undefined || freeqty === null ? 0 :freeqty,
                'gridGross': gridGrossAmt,
                'line': selectedGridRecord.line,
                'listPrice': selectedGridRecord.listPrice,
                'margin': margin,
                'oldMargin':(((selectedGridRecord.priceList - selectedGridRecord.priceStd2) / selectedGridRecord.priceList) * 100).toFixed(2),
                'orderId':selectedGridRecord.orderId,
                'orderLineId': selectedGridRecord.orderLineId,
                'orderedQty': selectedGridRecord.orderedQty,
                'pofreeqty':selectedGridRecord.pofreeqty,
                'priceList': selectedGridRecord.priceList,
                'priceStd': unitPrice,
                'priceStd1': formFieldsData.BasePriceChange,
                'priceStd2': selectedGridRecord.priceStd2,
                'productId':selectedGridRecord.productId,
                'productName':selectedGridRecord.productName,
                'productSearchKey':selectedGridRecord.productSearchKey,
                'receivedqty':selectedGridRecord.receivedqty,
                'receivingQty':orQty,
                'stockQty': selectedGridRecord.stockQty,
                'stockUomId': selectedGridRecord.stockUomId,
                'stockUomIdName':selectedGridRecord.stockUomIdName,
                'unitPrice':selectedGridRecord.unitPrice,
                'uomId': selectedGridRecord.uomId,
                'uomName':selectedGridRecord.uomName,
                'value':selectedGridRecord.value,
                'upc':selectedGridRecord.upc,
                'netUnitPrice':netUnitPrice,
                'taxId': selectedGridRecord.taxId,
                'taxName':selectedGridRecord.taxName,
                'taxRate': selectedGridRecord.taxRate,
                'totalTax':totalTax,
                'batchedProduct':selectedGridRecord.batchedProduct,
                'batchNo':batchNo,
                'lineNetGross':lineNetGross,
                'expiry_date':moment(expiry_date).format('YYYY-MM-DD')==="Invalid date"?null:moment(expiry_date).format('YYYY-MM-DD'),
                'mfg_date':moment(mfg_date).format('YYYY-MM-DD')==="Invalid date"?null:moment(mfg_date).format('YYYY-MM-DD'),
              }
              const newArray = []
            for (let index = 0; index < productData.length; index++) {
              const productIdFromArray = productData[index].productId;
              if(productIdFromArray !==selectedGridRecord.productId){
                newArray.push(productData[index])
              }
            }
            newArray.unshift(Obj)
            setProductData(newArray)
      }, 200);
    }

                                
    const scanUpcOrSku = (event) =>{
    form.resetFields(['GrossAmount','receivingQty','batchNo'])
    form.setFieldsValue({'mfg_date':'','expiry_date':''})
    const code = event.keyCode || event.which
    let productIdToMatch
    if(withOrwithoutPoValue === "1"){
      if(code === 13){
        skuform.resetFields()
        let upcOrSku;
          if(radioValue === 1){
            upcOrSku = 'upc'
          }else{
            upcOrSku = 'sku'
          }
          const index = productData.findIndex(element => {
            if(upcOrSku === 'sku'){
              return element.value === event.target.value
            }else{
              return element.upc === event.target.value
            }
        })
        if (index >= 0) {
          let additionalArray = []
          let arrayforForm = []
          for (let index = 0; index < productData.length; index++) {
            let element;
            if(upcOrSku === 'sku'){
              element = productData[index].value;
            }else{
              element = productData[index].upc;
            }
            if(element === event.target.value){
              const isBatchedFlag = productData[index].batchedProduct
            if(isBatchedFlag === 'Y'){
              setBatchModalVisible(true)
              setDisableBatchField(false)
            }else{
              setBatchModalVisible(false)
              setDisableBatchField(true)
            }
              productIdToMatch = productData[index].productId
              let orderedty = productData[index].orderedQty
              let receivingQty = productData[index].receivingQty === undefined || productData[index].receivingQty === '' || productData[index].receivingQty === 0 ? 1 : parseInt(productData[index].receivingQty) + 1
              let netUnitPrice;
                let taxOnUnitPrice;
                let totalTax;
                let priceStd = productData[index].priceStd
                let gridGross;
                let taxRate = productData[index].taxRate
                let totalDiscount;
                let lineNetGross;
              if(receivingQty > orderedty){
                message.error("Receivig qty should not more than ordered qty")
                form.setFieldsValue({'receivingQty':''})
              }else{
                if(productData[index].isPoTotalDiscoutValue === "Total value Discount") {
                  const totalPoDiscount = productData[index].poTotalDiscount
                    const avgDiscount = totalPoDiscount/orderedty
                    productData[index].discountvalue = (avgDiscount) * (receivingQty)
                    totalDiscount = (avgDiscount) * (receivingQty)
                    productData[index].totalDiscount = (totalDiscount).toFixed(2)
                       if(istaxincludedFlag === 'Y'){
                      netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }else{
                      const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                      netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }
                     productData[index].receivingQty = productData[index].receivingQty === undefined || detailsOfGridPo[index].productData === '' || detailsOfGridPo[index].productData === 0 ? 1 : parseInt(detailsOfGridPo[index].productData) + 1
                     productData[index].gridGross = gridGross
                     productData[index].DiscountType = productData[index].DiscountType
                     productData[index].netUnitPrice = netUnitPrice
                     productData[index].totalDiscount = (totalDiscount).toFixed(2)
                     productData[index].lineNetGross = lineNetGross
                     arrayforForm.push(productData[index])
                  // this.getWithoutPoCalculations()
                }else if(productData[index].isPoTotalDiscoutValue === "Percentage"){
                  const totalPoDiscount = productData[index].poTotalDiscount
                    const avgDiscount = totalPoDiscount/orderedty
                    totalDiscount = (avgDiscount) * (receivingQty)
                    productData[index].totalDiscount = (totalDiscount).toFixed(2)
                    if(istaxincludedFlag === 'Y'){
                      netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }else{
                      const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                      netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }
                     productData[index].receivingQty = productData[index].receivingQty === undefined || productData[index].receivingQty === '' || productData[index].receivingQty === 0 ? 1 : parseInt(productData[index].receivingQty) + 1
                     productData[index].gridGross = gridGross
                     productData[index].DiscountType = productData[index].DiscountType
                     productData[index].netUnitPrice = netUnitPrice
                     productData[index].totalDiscount = (totalDiscount).toFixed(2)
                     productData[index].lineNetGross =lineNetGross
                     arrayforForm.push(productData[index])
                }else if (productData[index].isPoTotalDiscoutValue === "Value"){
                  productData[index].discountvalue = productData[index].discountvalue
                  productData[index].totalDiscount = (productData[index].discountvalue) * (receivingQty)
                    totalDiscount = (productData[index].discountvalue) * (receivingQty)
                    if(istaxincludedFlag === 'Y'){
                      netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }else{
                      const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                      taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                      netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                      totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                      gridGross = (priceStd * receivingQty).toFixed(2)
                      lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                     }
                     productData[index].receivingQty = productData[index].receivingQty === undefined || productData[index].receivingQty === '' || productData[index].receivingQty === 0 ? 1 : parseInt(productData[index].receivingQty) + 1
                     productData[index].gridGross = gridGross
                     productData[index].DiscountType = productData[index].DiscountType
                     productData[index].netUnitPrice = netUnitPrice
                     productData[index].lineNetGross = lineNetGross
                     productData[index].totalDiscount = (totalDiscount).toFixed(2)
                     arrayforForm.push(productData[index])
                }else{
                  setDisabledDiscountType(false)
                  setDisableDiscountInput(true)
                  if(istaxincludedFlag === 'Y'){
                    netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
                    taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
                    totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                    gridGross = (priceStd * receivingQty).toFixed(2)
                    lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                   }else{
                    const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
                    taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
                    netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
                    totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
                    gridGross = (priceStd * receivingQty).toFixed(2)
                    lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
                   }
                   productData[index].receivingQty = productData[index].receivingQty === undefined || productData[index].receivingQty === '' || productData[index].receivingQty === 0 ? 1 : parseInt(productData[index].receivingQty) + 1
                   productData[index].gridGross = (priceStd * receivingQty).toFixed(2)
                   productData[index].DiscountType = productData[index].DiscountType
                   productData[index].netUnitPrice = netUnitPrice
                   productData[index].totalDiscount = totalDiscount
                   productData[index].lineNetGross = lineNetGross
              arrayforForm.push(productData[index])
                }
              }
            }
            additionalArray.push(productData[index])
          }
        const newData = arrayforForm[0]
        form.setFieldsValue({
          Value:newData.value,
          Name:newData.productName,
          UOM:newData.uomName,
          receivingQty:newData.receivingQty,
          FreeQty:newData.freeqty,
          orderedQty:newData.orderedQty,
          poFreeQty:newData.poFreeQty,
          receivedQty:newData.receivedqty,
          DiscountType:newData.DiscountType,
          discountValue:newData.discountvalue,
          BasePriceChange:newData.priceStd1,
          unitPrice:newData.priceStd,
          netUnitPrice:newData.netUnitPrice,
          multiplePopMRP:newData.priceList,
          margin:newData.margin,
          OldMargin:newData.oldMargin,
          GrossAmount:newData.gridGross,
          totalDiscount:newData.totalDiscount,
          batchNo:newData.batchNo,
          mfg_date:newData.mfg_date === undefined || newData.mfg_date === null ? null :moment(newData.mfg_date),
          expiry_date : newData.expiry_date === undefined || newData.expiry_date === null ? null :moment(newData.expiry_date),
          description:newData.description,
          netAmount:newData.lineNetGross
        })
        
          setSelectedGridRecord(arrayforForm[0])
          const newArray = []
          for (let index = 0; index < additionalArray.length; index++) {
            const productIdFromArray = additionalArray[index].productId;
            if(productIdFromArray !==productIdToMatch){
              newArray.push(additionalArray[index])
            }
          }
          newArray.unshift(arrayforForm[0])
          setTimeout(() => {
            skuform.resetFields(['upcsku'])
            setProductData([])
            setProductData(newArray)
          }, 1)
        }else{
          message.error("Product not found in po")
          form.resetFields()
          skuform.resetFields(['upcsku'])
        }
      }
    }else{
      let upcOrSku;
      if(radioValue === 1){
        upcOrSku = 'upc'
      }else{
        upcOrSku = 'sku'
      }
      if (code === 13) {
        const index = productData.findIndex(element => {
          if(upcOrSku === 'sku'){
            return element.value === event.target.value
          }else{
            return element.upc === event.target.value
          }
      })
      // skuform.resetFields(['upcsku'])
      if (index >= 0) {
        let additionalArray = []
        let arrayforForm = []
        let productIdToMatch2;
        for (let index = 0; index < productData.length; index++) {
          let element;
          if(upcOrSku === 'sku'){
            element = productData[index].value;
          }else{
            element = productData[index].upc;
          }
          if(element === event.target.value){
            const isBatchedFlag = productData[index].batchedProduct
            if(isBatchedFlag === 'Y'){
              setBatchModalVisible(true)
              setDisableBatchField(false)
            }else{
              setBatchModalVisible(false)
              setDisableBatchField(true)
            }
            productIdToMatch2 = productData[index].productId
            productData[index].receivingQty = productData[index].receivingQty === undefined || productData[index].receivingQty === '' || productData[index].receivingQty === 0 ? 1 : parseInt(productData[index].receivingQty) + 1
            productData[index].gridGross = (productData[index].receivingQty * productData[index].priceStd).toFixed(2)
            productData[index].lineNetGross = (productData[index].receivingQty * productData[index].netUnitPrice).toFixed(2)
            productData[index].DiscountType = productData[index].DiscountType
            productData[index].totalDiscount = productData[index].totalDiscount
            arrayforForm.push(productData[index])
          }
          additionalArray.push(productData[index])
        }
        const newData = arrayforForm[0]
        form.setFieldsValue({
          Value:newData.value,
          Name:newData.productName,
          UOM:newData.uomName,
          receivingQty:newData.receivingQty,
          FreeQty:newData.freeqty,
          orderedQty:newData.orderedQty,
          poFreeQty:newData.poFreeQty,
          receivedQty:newData.receivedqty,
          DiscountType:newData.DiscountType,
          discountValue:newData.discountvalue,
          BasePriceChange:newData.priceStd1,
          unitPrice:newData.priceStd,
          netUnitPrice:newData.netUnitPrice,
          multiplePopMRP:newData.priceList,
          margin:newData.margin,
          OldMargin:newData.oldMargin,
          GrossAmount:newData.gridGross,
          totalDiscount:newData.totalDiscount,
          batchNo:newData.batchNo,
          mfg_date:newData.mfg_date === undefined || newData.mfg_date === null ? null :moment(newData.mfg_date),
          expiry_date : newData.expiry_date === undefined || newData.expiry_date === null ? null :moment(newData.expiry_date),
          description:newData.description,
          netAmount:newData.lineNetGross
        })
        setSelectedGridRecord(arrayforForm[0])
        const newArray = []
          for (let index = 0; index < additionalArray.length; index++) {
            const productIdFromArray = additionalArray[index].productId;
            if(productIdFromArray !==productIdToMatch2){
              newArray.push(additionalArray[index])
            }
          }
        newArray.unshift(arrayforForm[0])
        setTimeout(() => {
          skuform.resetFields(['upcsku'])
          setProductData([])
          setProductData(newArray)
        }, 1)
      }else{
        message.error("Product not found")
        form.resetFields()
        skuform.resetFields(['upcsku'])
      }
    }
    }

    }

    const addCostCancel = () =>{
      setAddCostVisible(false)
    }
    
    const onSelectLandedCost = (e,data) =>{
      setSelectedLandedCost(data.props.data)
    }
    const addCostToGrid = () =>{
      const formFieldsData = otherCostForm.getFieldsValue(true);
      const uniqueId = uuid()
          .replace(/-/g, '')
          .toUpperCase()
      const costObj = {
        id : uniqueId,
        calcMethod:selectedLandedCost.calcMethod,
        name:selectedLandedCost.name,
        mProductId:selectedLandedCost.product.mProductId,
        costPrice:formFieldsData.costEntered,
        csTaxId:selectedLandedCost.csTaxId,
        pLcTypeId:selectedLandedCost.pLcTypeId
      }
      setOtherCostData([...otherCostData,costObj])
      setSelectedLandedCost([])
      setAddCostVisible(false)
      otherCostForm.resetFields();
    }

    const getLandedCost = async () =>{
      const getLandedCostResponse = await getLandedCostData()
      setLandedCostDropDownData(getLandedCostResponse)
    }
    
    const openCostModal = () =>{
      setAddCostVisible(true)
    }

   const disabledPreviousDate = current =>{
      return current && current < moment().startOf('day');
    }

    const closeSummaryModal = () =>{
      setSummaryModalVisible(false)
    }

    const createGRN = () =>{
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
        summaryForm.validateFields().then((values) => {
          let invoiceNo = values.summinvoiceNo
          let invoiceDate = moment(values.summinvoiceDate).format('YYYY-MM-DD')
      let movementDate = moment(values.summreceiptdate).format('YYYY-MM-DD') 
        let orderLineID = ''
    let productID = ''
    let uomID = ''
    let quantity = ''
    let orderID = ''
    let remark = ''
    let descriptioN = ''
    let stockUomID
    let freeqty = ''
    let unitPrice =''
    let listPrice =''
    let discountValue = ''
    let discountType = ''
    let totalDiscount = ''
    let PLcTypeId=''
    let Value=''
    let MProductId =''
    let CsTaxId=''
    let orderLineId = ''
    let batchNo =''
    let expiry_date = ''
    let mfg_date = ''
    const newOrderId = orderId

    const arrayForMutation = []
    const arrayForLandedCost = []
    for (let index2 = 0; index2 < otherCostData.length; index2++) {
      PLcTypeId = otherCostData[index2].pLcTypeId
      Value = otherCostData[index2].costPrice
      MProductId = otherCostData[index2].mProductId
      CsTaxId = otherCostData[index2].csTaxId
      arrayForLandedCost.push(
        `{
          pLcTypeId : "${PLcTypeId}",
          value :  ${Value},      
          productId : "${MProductId}",
          taxId : "${CsTaxId}"
        }`,
      )
    }
    
    for (let index = 0; index < grnSummaryData.length; index += 1) {
      batchNo = grnSummaryData[index].batchNo
      expiry_date = grnSummaryData[index].expiry_date==="Invalid date"?null:grnSummaryData[index].expiry_date
      mfg_date = grnSummaryData[index].mfg_date==="Invalid date"?null:grnSummaryData[index].mfg_date
      productID = grnSummaryData[index].productId
      uomID = grnSummaryData[index].uomId
      quantity = grnSummaryData[index].receivingQty
      remark = grnSummaryData[index].Remark
      freeqty = grnSummaryData[index].freeqty
      descriptioN =
      grnSummaryData[index].description === null || grnSummaryData[index].description === '' || grnSummaryData[index].description === undefined || grnSummaryData[index].description === "null"
          ? ''
          : grnSummaryData[index].description
      unitPrice = grnSummaryData[index].priceStd
      listPrice = grnSummaryData[index].priceList
      orderLineId = grnSummaryData[index].orderLineId === null || grnSummaryData[index].orderLineId === '' || grnSummaryData[index].orderLineId === undefined ? null : grnSummaryData[index].orderLineId 
      discountValue = grnSummaryData[index].discountvalue === null || grnSummaryData[index].discountvalue === undefined || grnSummaryData[index].discountvalue === "" ? 0 : grnSummaryData[index].discountvalue
      discountType = grnSummaryData[index].DiscountType === "Total value Discount" ? 'TV' : grnSummaryData[index].DiscountType === "Percentage" ? 'P' :grnSummaryData[index].DiscountType === "Value" ? 'V':null
      totalDiscount = grnSummaryData[index].totalDiscount === null || grnSummaryData[index].totalDiscount === undefined || grnSummaryData[index].totalDiscount === '' ? 0 : grnSummaryData[index].totalDiscount
      arrayForMutation.push(
        `{
                      productId : "${productID}",
                      uomId : "${uomID}",
                      qty : ${quantity},
                      orderLineId : ${orderLineId === null || orderLineId === "" || orderLineId === undefined ? null : `"${orderLineId}"`},
                      remark : ${remark === undefined ? null : remark === '' ? null : `"${remark}"`},
                      description : "${descriptioN}",
                      stockUomId : "${uomID}",
                      freeQty : ${freeqty === undefined || freeqty === null ? 0 : freeqty},
                      unitprice : ${unitPrice},
                      listprice : ${listPrice},
                      discountvalue : ${discountValue},
                      discountType:${discountType === null || discountType === "" || discountType === undefined ? null : `"${discountType}"`},
                      totalDiscount:${totalDiscount},
                      batchId:null,
                      batchNo:${batchNo === undefined || batchNo === null || batchNo === "" ? null :`"${batchNo}"`},
                      expiryDate:${expiry_date === undefined || expiry_date === null || expiry_date === "" || expiry_date === "Invalid date" ? null :`"${expiry_date}"`},
                      storagebinId:null,
                      startDate:${mfg_date === undefined || mfg_date === null || mfg_date === "" || mfg_date === "Invalid date" ? null :`"${mfg_date}"`},
                      endDate: ${expiry_date === undefined || expiry_date === null || expiry_date === "" || expiry_date === "Invalid date" ? null :`"${expiry_date}"`}
                    }`,
      )
    }
    setLoading(true)
    const getBookinOrder = {
      query: `mutation {
              createGRN (GRN : {
                bunitId : "${bunitId}"
                supplierId : "${supplierId}"
                supplierAddressId : "${supplierAddressId}"
                isReturn : "N"
                orderId : ${newOrderId === undefined ||newOrderId === null || newOrderId === '' ? null : `"${newOrderId}"`}
                remark : "${values.summremarks === undefined || null || '' ? '':values.summremarks}"
                supplierRef : "${invoiceNo}"
                movementDate : "${movementDate}"
                taskId : null
                invoiceDate : "${invoiceDate}"
                receiptLines : [${arrayForMutation}]
                landedCost:[${arrayForLandedCost}]
              }) {
              type
              code
              message
              documentNo
              extraParam
              }
            }`,
    }
    Axios({
      url: serverUrl,
      method: 'POST',
      data: getBookinOrder,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken.access_token}`,
      },
    }).then(response => {
      if (response.data.data.createGRN.code === '200') {
        const Status = response.data.data.createGRN.type
        const messageForSuccess = response.data.data.createGRN.message
        if (Status === 'Success') {
          setSummaryModalVisible(false)
          setLoading(false)
          setOrderId('')
          setSupplierId(null)
          setBunitId(null)
          setProductData([])
          setGrnSummaryData([])
          setwithOrwithoutPoValue("1")
          setOtherCostData([])
          headerform.setFieldsValue({
            'with-withoutpo': "With Po",  
            'supplier':null,
            'Po':null
           })
          form.resetFields()
          summaryForm.resetFields()
          const d1 = response.data.data.createGRN.extraParam
          const data = JSON.parse(d1)
          const recordId = data.receiptId
          getPrintCofirmation(recordId, messageForSuccess)
          form.resetFields()
        } else {
          message.error(messageForSuccess)
        }
      } else {
        message.error('Error while Creating GRN')
        setLoading(false)
      }
    })
    })
    }

   const getPrintCofirmation = (recordId, messageForSuccess) => {
      Modal.confirm({
        title: `${messageForSuccess}`,
        content: 'Do you want take Printout',
        okText: 'Yes',
        icon:null,
        // okType: 'danger',
        cancelText: 'No',
        onOk() {
          getPrintPdffile(recordId)
        },
        onCancel() {
          setSummaryModalVisible(false)
        },
      });
    }

   const getPrintPdffile = (recordId) => {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const RoleId = window.localStorage.getItem('userData')
      const getPrintPdfData = {
        query: `query {reportTemplate(ad_tab_id:"0953FB1201D94CA39671ADF5D4CB967B",recordId:"${recordId}")}`,
      }
      Axios({
        url: genericUrl,
        method: 'POST',
        async: true,
        crossDomain: true,
        data: getPrintPdfData,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${newToken.access_token}`,
          RoleId: `${RoleId.role_id}`,
        },
      }).then(response => {
        if (
          response.data.data.reportTemplate === null ||
          response.data.data.reportTemplate === 'null' ||
          response.data.data.reportTemplate === ''
        ) {
        } else {
          getPrintCommand(response.data.data.reportTemplate)
        }
      })
    }

    const getPrintCommand = fileName => {
      setProductData([])
      setSummaryModalVisible(false)
      Axios({
        url: `${fileDownloadUrl}`.concat(`${fileName}`),
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.setAttribute('id', 'downloadlink')
        link.href = fileURL
        link.setAttribute('download', `${fileName}`)
        link.click()
      })
    }

    const closeBatchModal = () =>{
      setBatchModalVisible(false)
    }

    const addBatch = () =>{
      setBatchModalVisible(false)
    }

   const downloadImportTemplate = () =>{
      const options = {
        fieldSeparator: ',',
        filename: 'GRNImport',
        // quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: false,
        headers: ['Sku','price','qty','freeqty','discountValue'] 
      }
      const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([{'Sku':'','price':'','qty':'','freeqty':'','discountValue':''}])
    }

  const  getImportModal = () =>{
        const formFieldsData = headerform.getFieldsValue(true);
          if (formFieldsData.supplier === undefined) {
            message.error('Please select supplier')
          } else {
            setImportModalVisible(true)
          }
    }
    const importModalClose = () =>{
      setImportModalVisible(false)
      importform.resetFields()
      document.getElementById('choosefile').value = null
    }

    const onSelectDiscountType = (e) =>{
      setImportDiscountType(e)
    }

    const readFileData = (evt) =>{
      const { files } = evt.target;
      const filename = files[0].name;
      const fileExt = filename.substr(filename.lastIndexOf("."), filename.length);
      if (fileExt !== ".csv") {
        message.error("Please select csv file");
      } else {
        const file = files[0];
        const reader = new FileReader();
        let fileTextData;
        reader.onload = (e) => {
          fileTextData = e.target.result;
          const rows = fileTextData.split(/\r?\n/);
          var dataArr = []
            for (var i = 1; i < rows.length - 1; i++) {
              var cells = rows[i].split(',')
              if (cells.length === 5) {
                dataArr.push({
                  sku: cells[0],
                  price: cells[1],
                  qty: cells[2],
                  freeqty: cells[3],
                  discount: cells[4],
                })
              }
            }
            if(withOrwithoutPoValue === '2'){          
              let flag = true;
              for (let index = 0; index < dataArr.length; index++) {
                const element = parseInt(dataArr[index].discount);
                if(element > 0 && importDiscountType === undefined){
                  flag = false
                }else{
                  flag = true
                }
              }
              if(flag === false){
                message.error("Please Select discout type")
                document.getElementById('choosefile').value = null
              }else{
                  if (dataArr.length > 0) {
                    setLoading(true)
                    const matchedArray = []
                    const unmatchedArray = []
                    for (let index1 = 0; index1 < productData.length; index1++) {
                      let boolean = true
                      const gridDataSku = productData[index1].value;
                      for (let index2 = 0; index2 < dataArr.length; index2++) {
                        const importSku = dataArr[index2].sku;
                        if(gridDataSku === importSku){
                          productData[index1].discountvalue = dataArr[index2].discount
                          productData[index1].DiscountType = importDiscountType
                          productData[index1].receivingQty = dataArr[index2].qty
                          productData[index1].freeqty = dataArr[index2].freeqty === undefined || dataArr[index2].freeqty === null || dataArr[index2].freeqty === "\r" || dataArr[index2].freeqty === "" ? 0 : dataArr[index2].freeqty
                          matchedArray.push(productData[index1])
                          boolean = false
                        }
                      }
                      if (boolean) {
                        unmatchedArray.push(productData[index1])
                      }
                    }
                    doImportCalculations(matchedArray,unmatchedArray)
              } else {
                message.error('Please import the file in required format.')
                document.getElementById('choosefile').value = null
              }
              }
            }else{
              if(dataArr.length > 0){
                setLoading(true)
                    const matchedArray = []
                    const unmatchedArray = []
                    for (let index1 = 0; index1 < productData.length; index1++) {
                      let boolean = true
                      const gridDataSku = productData[index1].value;
                      for (let index2 = 0; index2 < dataArr.length; index2++) {
                        const importSku = dataArr[index2].sku;
                        if(gridDataSku === importSku){
                          productData[index1].receivingQty = dataArr[index2].qty
                          productData[index1].freeqty = dataArr[index2].freeqty === undefined || dataArr[index2].freeqty === null || dataArr[index2].freeqty === "\r" || dataArr[index2].freeqty === "" ? 0 : dataArr[index2].freeqty
                          matchedArray.push(productData[index1])
                          boolean = false
                        }
                      }
                      if (boolean) {
                        unmatchedArray.push(productData[index1])
                      }
                    }
                    getPoCals(matchedArray,unmatchedArray)
              }else{
                message.error('Please import the file in required format.')
                document.getElementById('choosefile').value = null
              }
            }
        };
        reader.readAsText(file);
      }
    }
   const doImportCalculations = (matchedArray,unmatchedArray) =>{
      const newArray=[] 
      if(istaxincludedFlag === 'Y'){
        if(importDiscountType === undefined){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            const d3 = discount/orQty
            let price;
            const d4 = isNaN(d3)
                if(d4 === true){
                  price= 0
                }else{
                  price = d3
                }
            const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - price)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 =( unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const basePriceAfterTax = (basePrice/(1+taxRate/100))
            const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = null
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }
        else if(importDiscountType === 'Total value Discount'){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            const d3 = discount/orQty
            let price;
            const d4 = isNaN(d3)
                if(d4 === true){
                  price= 0
                }else{
                  price = d3
                }
            const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - price)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 =( unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const basePriceAfterTax = (basePrice/(1+taxRate/100))
            const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }else if(importDiscountType === 'Percentage'){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            
            const costPrice3 = (basePrice/(1+(taxRate/100)))
            const discountAmount = ((discount / 100) * costPrice3)
  
            const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - discountAmount)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const basePriceAfterTax = (basePrice/(1+taxRate/100))
            const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
  
        }else if(importDiscountType ==='Value'){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
  
            const netUnitPrice1 = ((basePrice/(1+taxRate/100)) - discount)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const basePriceAfterTax = (basePrice/(1+taxRate/100))
            const totalDiscount1 = ((basePriceAfterTax * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)

            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }
      }else{
        if(importDiscountType === undefined){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            const d3 = discount/orQty
            let price;
            const d4 = isNaN(d3)
                if(d4 === true){
                  price= 0
                }else{
                  price = d3
                }
            const netUnitPrice1 = (basePrice - price)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)

  
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }
        else if(importDiscountType === 'Total value Discount'){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            const d3 = discount/orQty
            let price;
            const d4 = isNaN(d3)
                if(d4 === true){
                  price= 0
                }else{
                  price = d3
                }
            const netUnitPrice1 = (basePrice - price)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)

            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }else if(importDiscountType === 'Percentage') {
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            
            const discountAmount = ((discount / 100) * basePrice)
  
            const netUnitPrice1 = (basePrice - discountAmount)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)

  
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }else if(importDiscountType ==='Value'){
          for (let index = 0; index < matchedArray.length; index++) {
            const orQty = matchedArray[index].receivingQty
            const discount = matchedArray[index].discountvalue === undefined || matchedArray[index].discountvalue === null || matchedArray[index].discountvalue === '' ? 0 :matchedArray[index].discountvalue
            const taxRate = matchedArray[index].taxRate
            const basePrice = matchedArray[index].priceStd1
            const MRP = matchedArray[index].priceList
            
            const netUnitPrice1 = (basePrice - discount)
            const taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1)
            const unitPrice1 = (parseFloat(netUnitPrice1) + parseFloat(taxOnUnitPrice))
            const gridGrossAmt1 = (unitPrice1 * orQty)
            const margin1 = (((MRP - unitPrice1) / MRP) * 100)
            const totalDiscount1 = ((basePrice * orQty) - (netUnitPrice1 * orQty))
            const totalTax1 = ((gridGrossAmt1) - (netUnitPrice1 * orQty))
  
            const netUnitPrice = (Math.round(netUnitPrice1 * 100) / 100).toFixed(2)
            const unitPrice = (Math.round(unitPrice1 * 100) / 100).toFixed(2)
            const gridGrossAmt = (Math.round(gridGrossAmt1 * 100) / 100).toFixed(2)
            const margin = (Math.round(margin1 * 100) / 100).toFixed(2)
            const totalTax = (Math.round(totalTax1 * 100) / 100).toFixed(2)
            const totalDiscount = (Math.round(totalDiscount1 * 100) / 100).toFixed(2)
            const lineNetGross = ( netUnitPrice * orQty).toFixed(2)

            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGrossAmt
            matchedArray[index].gridGross = gridGrossAmt
            matchedArray[index].discount = discount
            matchedArray[index].margin = margin
            matchedArray[index].DiscountType = importDiscountType
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].priceStd = unitPrice
            matchedArray[index].totalDiscount = totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
          }
        }
      }
      const newFinalArray = [...newArray,...unmatchedArray]
      setProductData(newFinalArray)
      setImportModalVisible(false)
      setLoading(false)
    }

  const  getPoCals = (matchedArray,unmatchedArray) =>{
      const newArray = []
      for (let index = 0; index < matchedArray.length; index++) {
        const DiscountType = matchedArray[index].DiscountType
        const discountValue = matchedArray[index].discountvalue
        const receivingQty = matchedArray[index].receivingQty
        const priceStd = matchedArray[index].priceStd
        const taxRate = matchedArray[index].taxRate
  
        const totalPoDiscount = matchedArray[index].poTotalDiscount
        const totalPoOrderedQty = matchedArray[index].orderedQty
        const avgDiscount = totalPoDiscount/totalPoOrderedQty
  
        let totalDiscount = (discountValue) * (receivingQty)
        let netUnitPrice;
        let taxOnUnitPrice;
        let totalTax;
        let gridGross;
        let lineNetGross;
        if(DiscountType === 'Value'){
          if(istaxincludedFlag === 'Y'){
            netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }else{
            const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
            netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGross
            matchedArray[index].gridGross = gridGross
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
        }else if(DiscountType === 'Total value Discount'){
          totalDiscount = (avgDiscount) * (receivingQty)
          if(istaxincludedFlag === 'Y'){
            netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }else{
            const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
            netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }
            matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGross
            matchedArray[index].gridGross = gridGross
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
        }else if (DiscountType === 'Percentage'){
          totalDiscount = (avgDiscount) * (receivingQty)
          if(istaxincludedFlag === 'Y'){
            netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }else{
            const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
            netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }
           matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGross
            matchedArray[index].gridGross = gridGross
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
        }else if (DiscountType === null || DiscountType === undefined){
          totalDiscount = 0
          if(istaxincludedFlag === 'Y'){
            netUnitPrice = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * priceStd).toFixed(2)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }else{
            const netUnitPrice1 = (priceStd/(1+taxRate/100)).toFixed(2)
            taxOnUnitPrice = ((taxRate/ 100) * netUnitPrice1).toFixed(2)
            netUnitPrice = parseFloat(priceStd) + parseFloat(taxOnUnitPrice)
            totalTax = (taxOnUnitPrice * receivingQty).toFixed(2)
            gridGross = (priceStd * receivingQty).toFixed(2)
            lineNetGross = (netUnitPrice * receivingQty).toFixed(2)
           }
           matchedArray[index].totalTax = totalTax
            matchedArray[index].grossAmount = gridGross
            matchedArray[index].gridGross = gridGross
            matchedArray[index].netUnitPrice = netUnitPrice
            matchedArray[index].totalDiscount=totalDiscount
            matchedArray[index].lineNetGross=lineNetGross
            newArray.push(matchedArray[index])
        }
      }
      const newFinalArray = [...newArray,...unmatchedArray]
      setProductData([])
      setProductData(newFinalArray)
      setImportModalVisible(false)
      setLoading(false)
    }

    let orderQuantityCount = 0
    let grossAmtCount = 0
    let orderQuantity = 0
    let summaryFreeQty = 0
    let freeQty = 0
    let totalOrderQty2 = 0
    let totalDiscountAmount = 0
    let lineNetGrossTotal=0
    let taxNameArray =[]
    for (let index = 0; index < grnSummaryData.length; index += 1) {
      orderQuantity = grnSummaryData[index].receivingQty
      summaryFreeQty = grnSummaryData[index].freeqty === null || grnSummaryData[index].freeqty === undefined || grnSummaryData[index].freeqty === "" ? 0 :grnSummaryData[index].freeqty
      const integer = parseFloat(orderQuantity, 10)
      orderQuantityCount += integer
      const integer2 = parseFloat(summaryFreeQty, 10)
      freeQty += integer2
      const grossAmtWithFloat = grnSummaryData[index].gridGross
      const lineNetGrossWithFloat = grnSummaryData[index].lineNetGross
      const totalDis = grnSummaryData[index].totalDiscount
      grossAmtCount += parseFloat(grossAmtWithFloat) 
      lineNetGrossTotal += parseFloat(lineNetGrossWithFloat) 
      totalDiscountAmount += parseFloat(totalDis)
    }
    totalOrderQty2 = freeQty + orderQuantityCount

    let result = grnSummaryData.reduce((c, v) => {
      c[v.taxName] = (c[v.taxName] || 0) + parseFloat(v.totalTax);
      return c;
    }, {});
    
    Object.keys(result).map((key) => {
      const taxObj ={
        'taxName':key,
        'taxValue':result[key]
      }
      taxNameArray.push(taxObj)
    });

    const summaryDiv =(
      <Card>
        <Form layout="vertical" form={summaryForm} name="summaryForm">
            <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <Form.Item name="summbusinessunit" label="Business Unit">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item name="summsupplier" label="Supplier">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summsupplierAddress" label="Supplier Address">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="poDoc" label="PO#">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}} />
              </Form.Item>
              </Col>
              </Row>
              <Row>
                <p />
                <br />
              </Row>
              <Row gutter={16}>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summdeliveryAddress" label="Delivery Address">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summorderdate" label="Order Date">
                <Input readOnly style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summreceiptdate" label="Receipt Date">
                <DatePicker  style={{width:'100%'}} /* disabledDate={disabledPreviousDate} */ /* onChange={disabledDateChange} */ />
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summremarks" label="Remarks">
                <Input  style={{borderLeft:'none',borderTop:'none',borderRight:'none'}}/>
              </Form.Item>
              </Col>
              </Row>
              <Row>
                <p />
                <br />
              </Row>
              <Row gutter={16}>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summinvoiceNo" 
              label="Invoice No"
              rules={[
                {
                  required: true,
                  message: "please input invoice no!",
                },
              ]}
              >
                <Input />
              </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
              <Form.Item name="summinvoiceDate" label="Invoice Date">
                <DatePicker style={{width:'100%'}} />
              </Form.Item>
              </Col>
            </Row>
          <Row>
            <p />
            <br />
          </Row>
        </Form>
        <div>
          <Table
            columns={summaryTableColumns}
            dataSource={grnSummaryData}
            style={{ fontSize: "12px" }}
            size="small"
            sticky={true}
            scroll={{ y: "35vh", x: "100%" }}
            pagination={false}
          />
        </div>
        <Row gutter={16}>
          <Col className="gutter-row" span={5}>
          <h4>No. of Products: {grnSummaryData.length}</h4>
          </Col>
          <Col className="gutter-row" span={5}>
          <h4>Total Discount: {totalDiscountAmount}</h4>
          </Col>
          <Col className="gutter-row" span={5}>
          <h4>Total Quantity: {totalOrderQty2}</h4>
          </Col>
          <Col className="gutter-row" span={5}>
          <h4>Total Net Amount: {lineNetGrossTotal.toFixed(2)}</h4>
          </Col>
          <Col className="gutter-row" span={4}>
          <h4>Total Amount: {grossAmtCount.toFixed(2)}</h4>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Collapse
              bordered={false}
              defaultActiveKey={['2']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="collapseClassStyle"
            >
              <Panel
                header="Delivery Notes"
                key="1"
                showArrow={false}
                className="collapseClassStyle"
              >
                <Input />
              </Panel>
            </Collapse>
          </Col>
          <Col className="gutter-row" span={6}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="collapseClassStyle"
            >
              <Panel
                header="Terms & Conditions"
                key="2"
                showArrow={false}
                className="collapseClassStyle"
              >
                <Input />
              </Panel>
            </Collapse>
          </Col>
          <Col className="gutter-row" span={6}>
            <span style={{ float: 'right' }}>
              <table style={{border:'1px solid #dddddd'}}>
                <thead>
                <tr>
                  <th style={{border:'1px solid #dddddd'}}>Landed Cost</th>
                </tr>
                </thead>
                <tbody>
                {otherCostData.map(data => (
                  <tr>
                    <td style={{border:'1px solid #dddddd'}}>
                      <span>{data.name}</span> &nbsp;: {data.costPrice}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </span>
          </Col>
          <Col className="gutter-row" span={6}>
            <span>
              <table style={{border:'1px solid #dddddd'}}>
              <thead>
                <tr>
                  <th style={{border:'1px solid #dddddd'}}>Tax Breakup</th>
                </tr>
              </thead>
              <tbody>
                {taxNameArray.map(data => (
                  <tr>
                    <td style={{border:'1px solid #dddddd'}}>
                      <span>{data.taxName}</span> &nbsp;: {(data.taxValue).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
              </table>
            </span>
          </Col>
        </Row>
      </Card>
    )
    
    return (
        <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
            <div>
                <Row>
                    <Col span={12}>
                        <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Goods Receipt</h2>
                    </Col>
                    <Col span={12}>
                        <span style={{ float: "right" }}>
                            <Button onClick={grnSummaryReview}  style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }}>Review</Button>
                        </span>
                    </Col>
                </Row>
                <Card style={{ marginBottom: "8px" }}>
                    <Form layout="vertical" form={headerform} name="control-hooks" onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={4}>
                                <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }}>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onSelect={onSelectBusinessUnit}
                                    >
                                        {bunitData.map((data, index) => (
                                            <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                                                {data.bUnitName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={4}>
                                <Form.Item name="with-withoutpo" label="Receive" style={{ marginBottom: "8px" }}>
                                    <Select
                                        className="ant-select-enabled"
                                        dropdownClassName="certain-category-search-dropdown"
                                        dropdownMatchSelectWidth={false}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: '100%' }}
                                        onSelect={onSelectOfWithORwithoutPo}
                                    >
                                        <Option key="1">With Po</Option>
                                        <Option key="2">Without Po</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={4}>
                                <Form.Item name="supplier" label="Supplier" style={{ marginBottom: "8px" }}>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={supplierOnchange}
                                    >
                                        {suppliersValuesInState.map((data, index) => (
                                            <Option key={data.recordid} istaxflag={data.istaxincluded} title={data.name} value={data.recordid}>
                                                {data.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {withOrwithoutPoValue === "1" ?
                            <Col className="gutter-row" span={6}>
                                <Form.Item name="Po" label="PO#" style={{ marginBottom: "8px" }}>
                                    <Select
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onSelect={onSelectPurchaseOrder}
                                        onFocus={getPurchaseOrderDocList}
                                    >
                                        {poDocList.map((data, index) => (
                                            <Option key={data.orderId} title={data.docNo} value={data.orderId} orderdate={data.dateOrdered}>
                                                {data.docToshow}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            :""}
                            <Col className="gutter-row" span={4}>
                                <Form.Item name="date" label="Date" style={{ marginBottom: "8px" }}>
                                    <DatePicker style={{ width: '100%' }} /* onChange={this.getDateChange} */ />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <Radio.Group
                            style={{ marginTop: '4%' }}
                            onChange={onChangeRadio}
                            value={radioValue}
                        >
                            <Radio value={1}>UPC</Radio>
                            <Radio value={2}>SKU</Radio>
                        </Radio.Group>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <Form layout="vertical" form={skuform} name="skuform">
                        <Form.Item name="upcsku" label="">
                            <Input
                                placeholder="Scan UPC/SKU"
                                // autoFocus={AutoFocusValue}
                                onKeyPress={scanUpcOrSku}
                                suffix={<img alt="img" src={barcodeImage} />}
                            />
                          </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <p style={{marginBottom:'8px'}} />
                <div>
            <Tabs defaultActiveKey={tabKey}  
            onChange={callbackTabs} 
            type="card" 
            tabBarStyle={{ marginBottom: "0px" }}
            tabBarExtraContent={
              <div>
                <Tooltip placement="top" title={"Download Template"}>
                  <Button size="small" onClick={downloadImportTemplate}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
                &nbsp;
                <Button size="small" onClick={getImportModal}>
                  Import
                </Button>
              </div>
            }
            >
              <TabPane tab="Products" key="10">
                <Card style={{ marginBottom: "8px" }}>
                <Form layout="vertical" form={form} name="editable-form" onFinish={onFinish}>
                    <Row gutter={16}>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="Value" label="SKU" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="Name" label="Name" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="UOM" label="UOM" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="receivingQty" label="Receiving Qty" style={{ marginBottom: "8px" }}>
                          <Input  onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="FreeQty" label="Free Qty" style={{ marginBottom: "8px" }}>
                          <Input  onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="orderedQty" label="Ordered Qty" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="poFreeQty" label="PO Free Qty" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="receivedQty" label="Received Qty" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                      {disabledDiscountType === true ? (
                        <Form.Item name="DiscountType" label="Discount Type" style={{ marginBottom: "8px" }}>
                          <Select 
                          allowClear 
                          onChange={OnChangeOrderQty}
                          disabled={withOrwithoutPoValue === '1' ? true : false}
                          >
                            <Option key="Percentage1" value="Percentage">
                              Percentage
                            </Option>
                            <Option key="Value2" value="Value">
                              Value
                            </Option>
                            <Option key="Totalvd3" value="Total value Discount">
                              Total value Discount
                            </Option>
                          </Select>
                        </Form.Item>
                        ) : (
                          <Form.Item name="DiscountType" label="Discount Type" style={{ marginBottom: "8px" }}>
                          <Select 
                          allowClear 
                          onChange={OnChangeOrderQty}
                          >
                            <Option key="Percentage1" value="Percentage">
                              Percentage
                            </Option>
                            <Option key="Value2" value="Value">
                              Value
                            </Option>
                            <Option key="Totalvd3" value="Total value Discount">
                              Total value Discount
                            </Option>
                          </Select>
                        </Form.Item>
                        )}
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="discountValue" label="Discount Value" style={{ marginBottom: "8px" }}>
                          <Input disabled={disableDiscountInput === "" || disableDiscountInput === false ? true : false} onChange={OnChangeOrderQty}  />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                      {disabledDiscountType === true ? (
                        <Form.Item name="BasePriceChange" label="Base Price" style={{ marginBottom: "8px" }}>
                          <Input 
                          disabled={withOrwithoutPoValue === '1' ? true : false}
                          onChange={OnChangeOrderQty} />
                        </Form.Item>
                        ) : (
                          <Form.Item name="BasePriceChange" label="Base Price" style={{ marginBottom: "8px" }}>
                          <Input 
                          onChange={OnChangeOrderQty} />
                        </Form.Item>
                        )}
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="unitPrice" label="Unit Price" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="netUnitPrice" label="Net Unit Price" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="multiplePopMRP" label="MRP" style={{ marginBottom: "8px" }}>
                          <Input readOnly  style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="margin" label="Margin" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="OldMargin" label="Old Margin" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="netAmount" label="Net Amount" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="GrossAmount" label="Gross Amount" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="totalDiscount" label="Total Discount" style={{ marginBottom: "8px" }}>
                          <Input readOnly style={{ border: "none", background: "rgb(241 243 247 / 68%)" }} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="batchNo" label="Batch No" style={{ marginBottom: "8px" }}>
                          <Input disabled={disableBatchField} onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="mfg_date" label="Mfg. Date" style={{ marginBottom: "8px" }}>
                          <DatePicker disabled={disableBatchField} onChange={OnChangeOrderQty} />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={4}>
                        <Form.Item name="expiry_date" label="Exp. Date" style={{ marginBottom: "8px" }}>
                          <DatePicker  
                          disabled={disableBatchField}
                          onChange={OnChangeOrderQty}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" span={6}>
                        <Form.Item name="description" label="Remarks" style={{ marginBottom: "8px" }}>
                          <Input /* onChange={OnChangeOrderQty} */ />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Card>
                <MainTable key="64" gridData={productData} getSelectedRecord={getSelectedRecord} />
              </TabPane>
              <TabPane tab="Landed Cost" key="12">
                <Card>
                  <Row gutter={16} style={{ marginBottom: "8px" }}>
                    <Col className="gutter-row" span={6}>
                      <Input
                        placeholder="Search"
                        style={{ width: "85%" }}
                        suffix={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.contentWindow.recordWindow.linesTab.linesSearchBar.icon} />}
                      />
                    </Col>
                    <Col className="gutter-row" span={4}>
                      <Button onClick={openCostModal} style={Themes.contentWindow.recordWindow.linesTab.LinesAddNewButton}>
                        +Add New
                      </Button>
                    </Col>
                  </Row>
                  <OtherCostTable gridData1={otherCostData} />
                </Card>
              </TabPane>
              <TabPane tab="Summary" key="13">
                {summaryDiv}
              </TabPane>
            </Tabs>
          </div>
          </div>
          <Modal
          visible={addCostVisible}
          closable={null}
          centered
          width="40%"
          // getContainer={false}
          footer={[
            <Button key="back" onClick={addCostCancel} >
              Cancel
            </Button>,
            <Button
              onClick={addCostToGrid}
            >
              Add
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>
            Other Cost
          </h3>
          <Card  style={{ marginBottom: "0px", border: "none" }}>
              <Form layout="vertical" form={otherCostForm} name="other-cost">
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      label="Cost Name"
                      name="costParameter"
                      key="costParam"
                      rules={[
                        {
                          required: true,
                          message: "please select cost name",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input, Option) => Option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onFocus={getLandedCost}
                        style={{ width: "100%" }}
                        onSelect={onSelectLandedCost}
                      >
                        {landedCostDropDownData.map((data, index) => (
                        <Option key={data.pLcTypeId} data={data} value={data.name}>
                        {data.name}
                        </Option>
                      ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item label="Value" 
                    name="costEntered"
                    key="costVal"
                    rules={[
                      {
                        required: true,
                        message: "please enter cost",
                      },
                    ]}
                    >
                    <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
        </Modal>
        <Modal
          visible={summaryModalVisible}
          closable={null}
          centered
          width="90%"
          footer={[
            <Button key="back" onClick={closeSummaryModal} >
              Cancel
            </Button>,
            <Button
              onClick={createGRN}
            >
              Submit
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>
            Goods Receipt Summary
          </h3>
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
            {summaryDiv}
          </Spin>
        </Modal>
        <Modal
          visible={batchModalVisible}
          closable={null}
          centered
          width="60%"
          footer={[
            <Button key="back" onClick={closeBatchModal} >
              Cancel
            </Button>,
            <Button
            onClick={addBatch}
            >
              Add Batch
            </Button>,
          ]}
        >
          <h3 style={{ textAlign: "center" }}>
            Batch Details
          </h3>
          <Card style={{ marginBottom: "8px" }}>
            <Form layout="vertical" form={form} name="editable-form" onFinish={onFinish}>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="batchNo" label="Batch No" style={{ marginBottom: "8px" }}>
                    <Input  onChange={OnChangeOrderQty} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="mfg_date" label="Mfg. Date" style={{ marginBottom: "8px" }}>
                    <DatePicker  onChange={OnChangeOrderQty} style={{width:'100%'}} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="expiry_date" label="Exp. Date" style={{ marginBottom: "8px" }}>
                    <DatePicker
                      onChange={OnChangeOrderQty} 
                      style={{width:'100%'}}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="receivingQty" label="Receiving Qty" style={{ marginBottom: "8px" }}>
                    <Input onChange={OnChangeOrderQty} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Modal>
        <Modal
          visible={importModalVisible}
          onCancel={importModalClose}
          closable={null}
          centered
          width="50%"
          footer={[
            <Button
              onClick={importModalClose}
            >
              Close
            </Button>
          ]}
        >
          <h3
            style={{ textAlign: 'center' }}
          >
            Import Products
          </h3>
          <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
          <Card>
              <Form layout="vertical" form={importform} name="importform">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="importProductsDiscountType" label="Discount Type">
                        <Select
                          style={{ width: '100%'}}
                          onSelect={onSelectDiscountType}
                        >
                          <Option key="25" value="Percentage">
                            Percentage
                          </Option>
                          <Option key="26" value="Value">
                            Value
                          </Option>
                          <Option key="27" value="Total value Discount">
                            Total value Discount
                          </Option>
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label=" ">
                       <input  id="choosefile" type="file" accept=".csv" onChange={readFileData} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          </Card>
          </Spin>
        </Modal>
        </Spin>
    )
}

export default GRN
