/* eslint-disable */
import React, { useEffect, useState } from "react";
import HeaderComponent from "./HeaderComponent";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Spin, message, Menu, Dropdown } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { genericUrl } from "../../constants/serverConfig";
import { ExportToCsv } from "export-to-csv";
import { useGlobalContext } from "../../lib/storage";
import BarChart from "./charts/BarChart";
import PieChart from "./charts/PieChart";
import LineChart from "./charts/LineChart";
import DonutChart from "./charts/DonutChart";
import GaugeChart from "./charts/GaugeChart";
import CombinationChartSingleYAxis from "./charts/CombinationChartSingleYAxis";
import CombinationChartDoubleYAxis from "./charts/CombinationChartDoubleYAxis";
import TableChart from "./charts/TableChart";
import WaterfallChart from "./charts/WaterfallChart";
import Axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
// import FullScreen from "../../assets/images/fullscreen.svg";
import settingIcon from "../../assets/images/settingsIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";
import CustomIcon from "../../assets/images/customicons";
import "./index.css";

const Dashboard = () => {
  const { dashboardId } = useParams();
  const { globalStore } = useGlobalContext();
  const { userPreferences } = globalStore;
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardFilters, setDashboardFilters] = useState([]);
  const [isComparableFlag, setIsComparableFlag] = useState("");
  const [kpiData, setKpiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kpiLoading, setKpiLoading] = useState({});
  const [dashboardDataInState, setDashboardDataInState] = useState([]);
  const [dashboardParams, setDashboardParams] = useState("");
  const [fullScreenValue, setFullScreenValue] = useState(false);
  const [chartKpiId, setChartKpiId] = useState("");
  const [chartType, setChartType] = useState("");
  const [chartTitle, setChartTitle] = useState("");
  const [chartProperties, setChartProperties] = useState("");

  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    // console.log("========>Inside Dashboard<=========")
    if (dashboardId) {
      getDashboardData();
    }
    return () => {
      setKpiData([]);
      setDashboardDataInState([]);
      setFullScreenValue(false);
    };
  }, [refresh]);

  useEffect(() => {
    if (userPreferences.enableMultiTab !== "Y") {
      setRefresh(new Date());
    }
  }, [dashboardId]);

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const dashhboardQuery = {
        query: `query {
              getDashboardJson(dashboardId:"${dashboardId}"){data, messageCode, title, message}
          }`,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };

      const serverResponse = await Axios.post(genericUrl, dashhboardQuery, { headers: headers }, { async: true }, { crossDomain: true });

      const dashboardJsonResponse = serverResponse.data.data.getDashboardJson;
      if (dashboardJsonResponse.messageCode === "200") {
        const dashboardData = JSON.parse(dashboardJsonResponse.data);
        const dashboardName = dashboardData.name;
        const dashboardFilters = dashboardData.filters;
        const kpiData = dashboardData.KPI;
        const previousYearArray = [];
        const defaultValueJSON = {};
        let isComparableFlag = dashboardData.isComparable;
        let defaultDateValues = "";
        for (let index = 0; index < kpiData.length; index++) {
          kpiData[index]["hide"] = "N";
        }
        if (kpiData !== undefined) {
          kpiData.sort(function (a, b) {
            return a.position_column - b.position_column;
          });
        }
        // console.log("===kpiData===",kpiData)
        setLoading(false);
        setDashboardName(dashboardName);
        setDashboardFilters(dashboardFilters);
        setKpiData(kpiData);
        setIsComparableFlag(isComparableFlag);

        for (let index = 0; index < dashboardFilters.length; index += 1) {
          const element = dashboardFilters[index];
          if (element.type === "DateRange") {
            const defaultValue = element.default_value_expression.split(";");
            defaultDateValues = defaultValue;
            /* this.setState({dateRange:defaultValue}) */
            defaultValueJSON[element.column_name] = defaultValue;

            if (isComparableFlag === "Y") {
              const fromDate = new Date(defaultDateValues[0]);
              const toDate = new Date(defaultDateValues[1]);
              const formattedFromDate = fromDate.getFullYear() - 1 + "-" + (fromDate.getMonth() + 1) + "-" + fromDate.getDate();
              const formattedToDate = toDate.getFullYear() - 1 + "-" + (toDate.getMonth() + 1) + "-" + toDate.getDate();
              previousYearArray.push(formattedFromDate, formattedToDate);
              defaultValueJSON[element.column_name.concat("_COMPARABLE_")] = previousYearArray;
            }
          }
        }

        const stringifiedJSON = JSON.stringify(defaultValueJSON);
        let jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
        setDashboardParams(defaultValueJSON);
        executeDashboard(kpiData, jsonToSend);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  const executeDashboard = (kpiData, dashboardParams) => {
    /* console.log("======kpiData======", kpiData);
    console.log("=======dashboardParams========", dashboardParams);
    console.log("=======dashboardFilters========", dashboardFilters);
    console.log("=======isComparableFlag========", isComparableFlag); */
    try {
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      let newData;
      for (let index = 0; index < kpiData.length; index++) {
        const element = kpiData[index];

        setKpiLoading((currentData) => ({ ...currentData, [element.kpi_id]: true }));
        const executeDashboardMutation = {
          query: `query {
          executeDashboard(dashboardId:"${dashboardId}",kpiId:"${element.kpi_id}",dashboardParam:"${dashboardParams}"){data, messageCode, title, message}
        }`,
        };
        Axios.post(genericUrl, executeDashboardMutation, { headers: headers }, { async: true }, { crossDomain: true }).then((execDashRes) => {
          const responseFromServer = execDashRes.data.data.executeDashboard;
          if (responseFromServer.title === "Success") {
            const dashboardData = JSON.parse(responseFromServer.data);
            // console.log("======dashboardData=====", dashboardData);
            newData = dashboardDataInState;
            for (const [key, value] of Object.entries(dashboardData)) {
              newData[key] = value;
            }
            setDashboardDataInState({ ...newData });
            setKpiLoading((currentData) => ({ ...currentData, [element.kpi_id]: false }));
            // dashBoardArr.push(dashboardData)
          } else {
            // message.error(`${element.title}-${responseFromServer.message}`)
            // console.log("======Error Data=====", responseFromServer.message);
            message.error(`${element.title}-${responseFromServer.message}`);
            setKpiLoading((currentData) => ({ ...currentData, [element.kpi_id]: false }));
          }
        });
      }
    } catch (error) {
      console.log("=====Error in execute Dashboard=====", error);
    }
  };

  const isLoad = (isLoading, data) => {
    // console.log("====data")
    if (isLoading === "Y") {
      setKpiLoading((currentData) => ({ ...currentData, [data]: true }));
    } else {
      setKpiLoading((currentData) => ({ ...currentData, [data]: false }));
    }
  };

  const amountFormat = (badgeValue) => {
    let result;
    if (badgeValue === undefined) {
      result = 0;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e9) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e9).toFixed(2)} B`;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e6) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e6).toFixed(2)} M`;
    } else if (Math.abs(Number(badgeValue)) >= 1.0e3) {
      result = `${(Math.abs(Number(badgeValue)) / 1.0e3).toFixed(2)} K`;
    } else {
      result = Math.abs(Number(badgeValue));
    }
    return result;
  };

  const responsiveDesignForColumn = {
    xxl: 12,
    xl: 12,
    lg: 12,
    xs: 12,
    sm: 12,
    md: 12,
  };

  const maximizeChart = (id, type, title, properties) => {
    // console.log("===kpiId====",id)
    setChartKpiId(id);
    setChartType(type);
    setChartTitle(title);
    setChartProperties(properties);
    /* let data = [...kpiData];
    setFullScreenValue(true);
    for (let index1 = 0; index1 < data.length; index1++) {
      if (data[index1].kpi_id !== id) {
        data[index1].hide = "Y";
      }
    };
    setKpiData(data);
    if (fullScreenValue === true) {
      for (let index1 = 0; index1 < data.length; index1++) {
        data[index1].hide = "N";
      }
      setKpiData(data);
      setFullScreenValue(false);
    } */
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    setLoading(false);
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const toggleNoScroll = (off) => {
    //	test if already exist:
    var a = Array.prototype.indexOf.call(document.body.classList, "no-scroll") + 1;
    //	remove if does exist, so as not to double up
    document.body.className = document.body.className.replace(" no-scroll", "");
    //	add only if off IS False OR off is empty & it did not previously exist (thus "toggle")
    if (off === false || (off !== true && !a)) document.body.className += " no-scroll";
    return document.body.classList;
  };

  const fullScreenMode = (value) => {
    if (value) {
      if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
        toggleNoScroll(true);
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        toggleNoScroll(false);
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  };

  const dashboardParamsAndKpiData = {
    kpiData: kpiData,
    dashboardParams: dashboardParams,
  };

  const userData = JSON.parse(localStorage.getItem("userData"));
  const currencySymbol = userData.currency;

  const filteredData = (data) => {
    // console.log("===Data===>",data)
    let newData = dashboardDataInState;
    const parsedJSON = data;
    for (const [key, value] of Object.entries(parsedJSON)) {
      newData[key] = value;
    }
    setDashboardDataInState({ ...newData });
    /* if (data.title === 'Error') {
      // console.log(title)
      message.error(`${data.message}`)
    } else {
      // console.log('=====success data====', data)
      let newData = this.state.dashboardDataInState
      const parsedJSON = data
      for (const [key, value] of Object.entries(parsedJSON)) {
        newData[key] = value
      }
      this.setState({ dashboardDataInState: newData, hideChartData: flag })
    } */
  };

  /* const showKpiOptions=()=>{

  } */

  const expandChart = (id) => {
    let data = [...kpiData];
    setFullScreenValue(true);
    for (let index1 = 0; index1 < data.length; index1++) {
      if (data[index1].kpi_id !== id) {
        data[index1].hide = "Y";
      }
    }
    setKpiData(data);
    if (fullScreenValue === true) {
      for (let index1 = 0; index1 < data.length; index1++) {
        data[index1].hide = "N";
      }
      setKpiData(data);
      setFullScreenValue(false);
    }
  };

  const refreshChart = (kpiId) => {
    // console.log("===dashboardParams===",dashboardParams)
    try {
      const stringifiedJSON = JSON.stringify(dashboardParams);
      let jsonToSend = stringifiedJSON.replace(/"/g, '\\"');
      const newToken = JSON.parse(localStorage.getItem("authTokens"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${newToken.access_token}`,
      };
      let newData;

      setKpiLoading((currentData) => ({ ...currentData, [kpiId]: true }));
      const executeDashboardMutation = {
        query: `query {
        executeDashboard(dashboardId:"${dashboardId}",kpiId:"${kpiId}",dashboardParam:"${jsonToSend}"){data, messageCode, title, message}
      }`,
      };
      Axios.post(genericUrl, executeDashboardMutation, { headers: headers }, { async: true }, { crossDomain: true }).then((execDashRes) => {
        const responseFromServer = execDashRes.data.data.executeDashboard;
        if (responseFromServer.title === "Success") {
          const dashboardData = JSON.parse(responseFromServer.data);
          // console.log("======dashboardData=====", dashboardData);
          newData = dashboardDataInState;
          for (const [key, value] of Object.entries(dashboardData)) {
            newData[key] = value;
          }
          setDashboardDataInState({ ...newData });
          setKpiLoading((currentData) => ({ ...currentData, [kpiId]: false }));
          // dashBoardArr.push(dashboardData)
        } else {
          // message.error(`${element.title}-${responseFromServer.message}`)
          // console.log("======Error Data=====", responseFromServer.message);
          // message.error(`${element.title}-${responseFromServer.message}`)
          setKpiLoading((currentData) => ({ ...currentData, [kpiId]: false }));
        }
      });
    } catch (error) {
      console.log("=====Error in execute Dashboard=====", error);
    }
  };

  const exportTableData = (chartKpiId, chartTitle, chartProperties) => {
    const tableProperties = JSON.parse(chartProperties);
    const columnsDataArr = tableProperties.columnsData;
    const headersArr = [];
    for (let index = 0; index < columnsDataArr.length; index++) {
      headersArr.push(columnsDataArr[index].title);
    }
    // console.log('======headersArr=====', headersArr)

    const options = {
      fieldSeparator: ",",
      filename: chartTitle,
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: headersArr,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dashboardDataInState[chartKpiId]);
  };

  const kpiOptionsMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => expandChart(chartKpiId)}>
        Expand
      </Menu.Item>
      <Menu.Item key="2" onClick={() => refreshChart(chartKpiId)}>
        Refresh
      </Menu.Item>
      {chartType === "Table Chart" ? (
        <Menu.Item key="3" onClick={() => exportTableData(chartKpiId, chartTitle, chartProperties)}>
          Download
        </Menu.Item>
      ) : (
        ""
      )}
    </Menu>
  );

  return (
    <Spin indicator={<LoadingOutlined spin />} spinning={loading}>
      <Scrollbars
        autoHide
        // Hide delay in ms
        autoHideTimeout={1000}
        // Duration for hide animation in ms.
        autoHideDuration={200}
        thumbSize={100}
        // renderView={renderView}
        // renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        style={{ height: "90vh" }}
      >
        <HeaderComponent
          dashboardId={dashboardId}
          isComparableFlag={isComparableFlag}
          dashboardFilters={dashboardFilters}
          dashboardTitle={dashboardName}
          fullScreen={fullScreenMode}
          refreshDashboard={getDashboardData}
          kpiData={kpiData}
          filteredData={filteredData}
          loadingAfterFiltersApplied={isLoad}
        />
        <Row gutter={8}>
          {kpiData.map((kpiContent, index) => {
            const kpiUIProperties = JSON.parse(kpiContent.properties);
            let bgColor;
            let textColor;
            /* let cardHeight
            let previousYearDate */
            if (kpiUIProperties === null) {
              bgColor = "white";
              textColor = "black";
            } else {
              if (kpiUIProperties["bgcolor"] === undefined) {
                bgColor = "white";
              } else {
                bgColor = kpiUIProperties["bgcolor"];
              }

              if (kpiUIProperties["textColor"] === undefined) {
                textColor = "#19181A";
              } else {
                textColor = kpiUIProperties["textColor"];
              }
            }
            const netPercentage =
              ((dashboardDataInState[kpiContent.kpi_id] - dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")]) /
                dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")]) *
              100;
            if (kpiContent.hide === "N") {
              return (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: fullScreenValue === true && kpiContent.type !== "Badge" ? 24 : kpiContent.column_space * 2 }}
                  lg={{ span: fullScreenValue === true && kpiContent.type !== "Badge" ? 24 : kpiContent.column_space * 2 }}
                  xl={{ span: fullScreenValue === true && kpiContent.type !== "Badge" ? 24 : kpiContent.column_space * 2 }}
                  xxl={{ span: fullScreenValue === true && kpiContent.type !== "Badge" ? 24 : kpiContent.column_space * 2 }}
                  style={{ marginBottom: "8px" }}
                >
                  <Spin
                    indicator={
                      <div>
                        <CustomIcon name="logo" />
                      </div>
                    }
                    spinning={kpiLoading[kpiContent.kpi_id]}
                  >
                    <Card
                      style={{
                        borderRadius: "6px",
                        backgroundColor: bgColor,
                        display: kpiContent.isactive === "Y" || kpiContent.isactive === undefined ? "block" : "none",
                        height:
                          fullScreenValue === true && kpiContent.type !== "Badge"
                            ? "81.3vh"
                            : kpiContent.type === "Badge"
                            ? "12.9vh"
                            : kpiContent.widget_height === undefined || kpiContent.widget_height === null
                            ? "40vh"
                            : `${kpiContent.widget_height}vh`,
                      }}
                    >
                      <span className="chartTitle">
                        <span style={{ fontSize: "12px", fontWeight: kpiContent.type === "Badge" ? "none" : "600" }}>{kpiContent.title}</span>
                        <span className="maxIcon">
                          {kpiContent.type !== "Badge" ? (
                            fullScreenValue === false ? (
                              <Dropdown overlay={kpiOptionsMenu} trigger={["click"]} placement="bottomRight">
                                <img
                                  alt="maximize"
                                  height="13px"
                                  width="13px"
                                  src={settingIcon}
                                  style={{ float: "right", cursor: "pointer" }}
                                  onClick={() => maximizeChart(kpiContent.kpi_id, kpiContent.type, kpiContent.title, kpiContent.properties)}
                                />
                              </Dropdown>
                            ) : (
                              <img
                                alt="minimize"
                                height="13px"
                                width="13px"
                                src={closeIcon}
                                style={{ float: "right", cursor: "pointer" }}
                                onClick={() => expandChart(kpiContent.kpi_id)}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </span>
                      <br />
                      {kpiContent.type === "Badge" ? (
                        <>
                          <span
                            style={{
                              fontSize: "20px",
                              color: "#19181A",
                              // letterSpacing: "0px",
                              opacity: "0.8",
                              fontWeight: "bold",
                              color: textColor,
                            }}
                          >
                            {amountFormat(dashboardDataInState[kpiContent.kpi_id])}
                          </span>
                          &nbsp;
                          <span
                            style={{
                              // textAlign: "right",
                              fontSize: "12px",
                              color: netPercentage < 0 ? "#F9656F" : "#0DBC70",
                              fontWeight: "bold",
                            }}
                          >
                            {loading === false ? (
                              kpiContent.is_comparable === "Y" &&
                              (dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")] !== null ||
                                dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")] !== undefined) ? (
                                `${netPercentage === "NaN" ? 0 : ` ${netPercentage < 0 ? "" : "+"}${netPercentage.toFixed(2)}`}%`
                              ) : (
                                <br />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                          <br />
                          {kpiContent.is_comparable === "Y" ? (
                            <span style={{ fontSize: "12px" }}>
                              Compared to {kpiContent.currency_field !== undefined && kpiContent.currency_field !== null ? currencySymbol : ""}&nbsp;
                              <span style={{ fontWeight: "bold" }}>
                                {dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")] !== null ||
                                dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")] !== undefined
                                  ? amountFormat(dashboardDataInState[kpiContent.kpi_id.concat("_COMPARABLE_")])
                                  : ""}
                              </span>
                              &nbsp;
                              <span>in {new Date().getFullYear() - 1}</span>&nbsp;
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : kpiContent.type === "Bar Chart" ? (
                        <BarChart
                          barChartdata={dashboardDataInState[kpiContent.kpi_id]}
                          barChartProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Pie Chart" ? (
                        <PieChart
                          pieChartdata={dashboardDataInState[kpiContent.kpi_id]}
                          pieChartProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Line Chart" ? (
                        <LineChart
                          lineChartdata={dashboardDataInState[kpiContent.kpi_id]}
                          lineChartProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Donut Chart" ? (
                        <DonutChart
                          donutChartdata={dashboardDataInState[kpiContent.kpi_id]}
                          donutChartProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Gauge Chart" ? (
                        <GaugeChart
                          gaugeChartdata={dashboardDataInState[kpiContent.kpi_id]}
                          gaugeChartProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Combination Chart With Single Y axis" ? (
                        <CombinationChartSingleYAxis
                          combinationChartSingleYAxisdata={dashboardDataInState[kpiContent.kpi_id]}
                          combinationChartSingleYAxisProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Combination Chart With Double Y axis" ? (
                        <CombinationChartDoubleYAxis
                          combinationChartDoubleYAxisdata={dashboardDataInState[kpiContent.kpi_id]}
                          combinationChartDoubleYAxisProperties={JSON.parse(kpiContent.properties)}
                          height={kpiContent.widget_height}
                          fullScreenValue={fullScreenValue}
                        />
                      ) : kpiContent.type === "Table Chart" ? (
                        <>
                          <TableChart
                            tableChartData={dashboardDataInState[kpiContent.kpi_id]}
                            tableKpi={kpiContent}
                            dashboardParamsAndKpiData={dashboardParamsAndKpiData}
                            fullScreenValue={fullScreenValue}
                          />
                        </>
                      ) : kpiContent.type === "Waterfall Chart" ? (
                        <WaterfallChart waterFallChartdata={dashboardDataInState[kpiContent.kpi_id]} fullScreenValue={fullScreenValue} />
                      ) : (
                        ""
                      )}
                    </Card>
                  </Spin>
                </Col>
              );
            } else {
              return;
            }
          })}
        </Row>
      </Scrollbars>
    </Spin>
  );
};

export default Dashboard;
