import React from "react";
import { Row, Col } from "antd";
import { useGlobalContext } from "../../lib/storage";

const StatusBar = (props) => {
  const { globalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;

  const { statusBar } = props;
  return (
    <Row gutter={[0, 20]} style={Themes.contentWindow.recordWindow.RecordHeader.StatusBar}>
      <Col span={24} style={{ maxWidth: "100%" }}>
        {statusBar.map((status, index) => (
          <span style={Themes.contentWindow.recordWindow.RecordHeader.statusBarKeys} key={`${index}`}>
            {status.titleName} : <span style={Themes.contentWindow.recordWindow.RecordHeader.statusBarValues}>{status.titleValue}</span> &#8739;&nbsp;
          </span>
        ))}
      </Col>
    </Row>
  );
};

export default StatusBar;
