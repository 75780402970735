import axios from "axios";
import { serverUrl } from "../constants/serverConfig";

let localToken;
const customInstance = axios.create();
customInstance.defaults.baseURL = serverUrl;
customInstance.defaults.method = "POST";
customInstance.defaults.headers.post["Content-Type"] = "application/json";

customInstance.interceptors.request.use(
  (config) => {
    if (!localToken) {
      localToken = JSON.parse(localStorage.getItem("authTokens"));
    }
    config.headers.Authorization = `${localToken.token_type} ${localToken.access_token}`;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

customInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { message } = JSON.parse(JSON.stringify(error));
    if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
      localStorage.clear();
      window.location.replace("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

const getDeliveryLocation = async (e) => {
  try {
    const deliveryData = await customInstance({
      data: {
        query: `query {
            getDeliveryLocations (bUnitId : "${e}")
        }`,
      },
    });
    return JSON.parse(deliveryData.data.data.getDeliveryLocations);
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getSupplierProduct = async (supplierId, bunitId) => {
  try {
    const supplierProductData = await customInstance({
      data: {
        query: `query {
          getSupplierProduct(supplierId : "${supplierId}",bUnitId : "${bunitId}") {
            supplierProductId
            clientId
            clientName
            bUnitId
            bUnitName
            supplierId
            supplierName
            productId
            productName
            productCode
            qtyOnHand
            uomId
            uomName
            productCategoryId
            productcategoryName
            taxCategoryId
            taxCategoryName
            taxId
            taxName
            taxRate
            priceStd
            priceList
            twoWeekSale
            fourWeekSale
            upc
            description
            istaxincluded
            salePrice
            restrictMargin
            alternateUomList 
                { alternateUomId uomId uomName }
            margin
        }
      }`,
      },
    });
    const data = supplierProductData.data.data.getSupplierProduct;
    const tempArray = [];
    for (let index = 0; index < data.length; index++) {
      const obj = {
        productCategoryName: data[index].productcategoryName,
        name: data[index].productName,
        description: data[index].description,
        twoWeekSale: data[index].twoWeekSale,
        qtyOnHand: data[index].qtyOnHand,
        productId: data[index].productId,
        key: data[index].productId,
        uomName: data[index].uomName,
        uomId: data[index].uomId,
        priceList: data[index].priceList,
        priceStd: data[index].priceStd,
        unitPrice: data[index].priceStd,
        netUnitPrice: data[index].priceStd,
        unitPrice1: data[index].priceStd,
        priceList1: data[index].priceList,
        priceStd1: data[index].priceStd,
        priceStd2: data[index].priceStd,
        taxId: data[index].taxId,
        value: data[index].productCode,
        responseMargin: data[index].margin,
        upc: data[index].upc,
        taxName: data[index].taxName,
        taxRate: data[index].taxRate,
        salePrice: data[index].salePrice,
        restrictMargin: data[index].restrictMargin,
        skuName: data[index].productCode + "-" + data[index].productName,
      };
      tempArray.push(obj);
    }
    return tempArray;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getLandedCostData = async () => {
  try {
    const deliveryData = await customInstance({
      data: {
        query: `query{
          getLctype{
              pLcTypeId
              key
              name
              product{
                  mProductId
                  name
              }
              csTaxId
              calcMethod
          }
      }`,
      },
    });
    return deliveryData.data.data.getLctype;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getInventoryProduct = async (bunitId, mWarehousId) => {
  try {
    const inventoryProductData = await customInstance({
      data: {
        query: `query{ getInventoryProduct(bUnit:"${bunitId}", warehouseId:"${mWarehousId}"){
          mProductId
          value
          name 
          upc
          csUomId
          uomName 
          }
          }`,
      },
    });
    return inventoryProductData.data.data.getInventoryProduct;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getStockCountProduct = async (bunitId, mWarehousId) => {
  try {
    const inventoryProductData = await customInstance({
      data: {
        query: `query{ getStockCount(bUnit:"${bunitId}", warehouseId:"${mWarehousId}"){
          mProductId
          value
          name 
          upc
          csUomId
          uomName
          mBatch{
              mBatchId
              batchno
              startdate
              enddate
          }    
          }
          }`,
      },
    });
    return inventoryProductData.data.data.getStockCount;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getgetAgents = async (bunitId) => {
  try {
    const agentsData = await customInstance({
      data: {
        query: `query{
          getAgent (bUnitId:null)
          {
                  agentId
                  name
          }
          }`,
      },
    });
    return agentsData.data.data.getAgent;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getApparelProducts = async (value, bunitId, supplierId) => {
  try {
    const apparelData = await customInstance({
      data: {
        query: `query{
          getApparelProduct(productName:"${value}",
          bUnitId:"${bunitId}",supplierId:"${supplierId}"){
            mProductId 
            value         
            name            
            description 
            brandId
            brandName
            hsnCodeId
            hsnName         
            uom{
               csUomId
               name
           }
           productCategory{
               mProductCategoryId         
               name  
           }               
           taxCategory{
              csTaxcategoryID
              name
              overRideTax
              overRideCondition
              contraTaxCategoryId
              contraTaxCategory
              {
              contraTaxCategoryName
              contraTaxId
              contraTaxName
              contraRate
              }
           }        
           taxRate{
               csTaxID
               name
               rate
           } 
           pPriceList{
               pPriceListID
               name
               isTaxIncluded
               isDefault
           }             
       }
      }`,
      },
    });
    return apparelData.data.data.getApparelProduct;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getSpecificApparelProduct = async (value, bunitId, supplierId) => {
  try {
    const apparelData = await customInstance({
      data: {
        query: `query{
          getApparelProduct(productName:"${value}",
          bUnitId:"${bunitId}",supplierId:"${supplierId}"){
            mProductId 
            value         
            name            
            description 
            brandId
            brandName
            hsnCodeId
            hsnName         
            uom{
               csUomId
               name
           }
           productCategory{
               mProductCategoryId         
               name  
           }               
           taxCategory{
            csTaxcategoryID
            name
            overRideTax
            overRideCondition
            contraTaxCategoryId
            contraTaxCategory
            {
            contraTaxCategoryName
            contraTaxId
            contraTaxName
            contraRate
            }
           }        
           taxRate{
               csTaxID
               name
               rate
           } 
           pPriceList{
               pPriceListID
               name
               isTaxIncluded
               isDefault
           }             
       }
      }`,
      },
    });
    return apparelData.data.data.getApparelProduct;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getDraftPoDocs = async (supplierId, bunitId) => {
  try {
    const draftProductData = await customInstance({
      data: {
        query: `query {     
          getDraftPO(bUnitId : "${bunitId}" supplierId : "${supplierId}") {         
              orderId         
              docNo         
          } 
      }`,
      },
    });
    return draftProductData.data.data.getDraftPO;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getDraftpoProducts = async (bunitId, supplierId, e) => {
  try {
    const draftProductData = await customInstance({
      data: {
        query: `query {       
          getDraftOrderLines (bunitId : "${bunitId}" supplierId: "${supplierId}" orderIds: ["${e}"])
          {         
              orderLineId        
              orderId         
              documentNo         
              line         
              productId         
              productSearchKey         
              productName         
              uomId         
              uomName         
              orderedQty         
              description         
              receivedqty         
              stockUomId         
              stockUomIdName         
              stockQty         
              unitPrice         
              listPrice         
              pofreeqty         
              discountvalue         
              discountapplicable         
              discounttype       
              totaldiscount
              batchedProduct
              shelfLife
              netStd
              grossStd
              salePrice
              upc
              pCategoryName
              margin
              marginStd
              restrictMargin
              taxRate{
                  csTaxID
                  name
                  rate
              }
          }   
      }`,
      },
    });
    return draftProductData.data.data.getDraftOrderLines;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getStockAllocationData = async (bUnitId, warehouseId) => {
  try {
    const stockAllocationData = await customInstance({
      data: {
        query: `query{ getSTOpenOrders(bUnitId:"${bUnitId}",warehouseId:"${warehouseId}")
        {       mProductId
                name    
                value
                uom{
                    csUomId
                    name
                }
                qty
                qtyOnHand
                stockTransferOrder{
                    stockTransferOrderId
                    stockTransferOrderLineId
                    cSBunitID
                    bUnitName
                    qty
                }
            }
        }`,
      },
    });
    return stockAllocationData;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getConfirmDataForStockAllocation = async (order) => {
  try {
    const confirmData = await customInstance({
      data: {
        query: `mutation {
          confirmSTOrder(stOrder: {
            order : [${order}]
          })        
          {
              status
              message
          } 
      }`,
      },
    });
    return confirmData;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getRoleBusinessUnit = async (userId) => {
  try {
    const buData = await customInstance({
      data: {
        query: `query {
          getroleBunit(userId : "${userId}")
          {
              csUserId
              defaultCsRoleId
              roleName
              defaultCsBunitId
              bUnitName
            isLocalPurchase
              userBunit{
                  csUserId
                  csBunitId
                  bUnitName
                  isLocalPurchase
              }
        }    
        }`,
      },
    });
    return buData.data.data.getroleBunit;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getSupplierCategory = async () => {
  try {
    const supplierCategoryData = await customInstance({
      data: {
        query: `query{
          getSupplierCategory(bunitId:"0"){
            pSupplierCategoryId
            value
            name
          }
          
        }`,
      },
    });
    // console.log("------supplierCategoryData--------",supplierCategoryData.data.data.getSupplierCategory)
    return supplierCategoryData.data.data.getSupplierCategory;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getSupplierRegion = async (clientId) => {
  try {
    const supplierRegionData = await customInstance({
      data: {
        query: `query{
          getRegion(clientId:"${clientId}"){
            csRegionID
            value
            name
          }  
        }`,
      },
    });
    // console.log("------supplierCategoryData--------",supplierCategoryData.data.data.getSupplierCategory)
    return supplierRegionData.data.data.getRegion;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getTaxCategory = async (clientId) => {
  try {
    const taxCategoryData = await customInstance({
      data: {
        query: `query{
          getTaxCategory(clientId:"${clientId}"){
            csTaxcategoryID
            description
            name
          }  
        }`,
      },
    });
    // console.log("------supplierCategoryData--------",supplierCategoryData.data.data.getSupplierCategory)
    return taxCategoryData.data.data.getTaxCategory;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getBrand = async (clientId) => {
  try {
    const brandData = await customInstance({
      data: {
        query: `query{
          getBrand(clientId:"${clientId}"){
            brandId
            name
            value
          }  
        }`,
      },
    });
    // console.log("------supplierCategoryData--------",supplierCategoryData.data.data.getSupplierCategory)
    return brandData.data.data.getBrand;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getProductCategory = async () => {
  try {
    const productCategoryData = await customInstance({
      data: {
        query: `query{
          getProductCategory(tillId:null){
              mProductCategoryId
              name
              value
              description
              imageurl
              parentCategory{
                  mProductCategoryId
                  name
                  value
                  description
                  imageurl
                  parentCategory{
                      mProductCategoryId
                      name
                      value
                      description
                      imageurl
                      parentCategory{
                          mProductCategoryId
                          name
                          value
                          description
                          imageurl
                          parentCategory{
                              mProductCategoryId
                              name
                              value
                              description
                              imageurl
                          }
                      }
                  }
              }
          }
      }`,
      },
    });
    // console.log("------supplierCategoryData--------",supplierCategoryData.data.data.getSupplierCategory)
    return productCategoryData.data.data.getProductCategory;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getUOM = async (clientId) => {
  try {
    const uomData = await customInstance({
      data: {
        query: `query{
          getUom(clientId:"${clientId}"){
            csUomId
            ediCode
            name
          }  
        }`,
      },
    });
    // console.log("------uomData.data.data.getUom--------",uomData.data.data.getUom)
    return uomData.data.data.getUom;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getPoOrderLineProducts = async (e, bunitId, supplierId) => {
  try {
    const pOrderLineProducts = await customInstance({
      data: {
        query: `query {
          getOpenOrderLines (bunitId : "${bunitId}"
          supplierId : "${supplierId}"
          orderIds : ${e === "" ? null : `["${e}"]`}
          date : null) {
          orderLineId
          orderId         
          documentNo         
          line         
          productId         
          productSearchKey         
          productName         
          uomId         
          uomName         
          orderedQty         
          description         
          receivedqty         
          stockUomId         
          stockUomIdName         
          stockQty         
          unitPrice         
          listPrice         
          pofreeqty         
          discountvalue         
          discountapplicable         
          discounttype       
          totaldiscount
          batchedProduct
          shelfLife
          taxRate{
              csTaxID
              name
              rate
          }
          }
      }`,
      },
    });
    return pOrderLineProducts.data.data.getOpenOrderLines;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getPurchaseOrderDocData = async (bunitId, supplierId) => {
  try {
    const purchaseOrderDocData = await customInstance({
      data: {
        query: `query {
          getOpenPO(bUnitId : ${bunitId === "" ? null : `"${bunitId}"`} supplierId : ${supplierId === "" ? null : `"${supplierId}"`}) {
        orderId         
        docNo         
        dateOrdered         
        supplierId         
        supplierIdName         
        bUnitLocationId         
        bUnitId         
        bUnitIdName         
        islocalpurchase   
        totalNetAmount  
        totalGrossAmount 
          }
      }`,
      },
    });
    return purchaseOrderDocData.data.data.getOpenPO;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};
const getSupplierProductsList = async (e) => {
  try {
    const SupplierProducts = await customInstance({
      data: {
        query: `query {
          getSupplierProduct(supplierId : "${e}"
          sku : null
          upc : null) {
              supplierProductId
              clientId
              clientName
              bUnitId
              bUnitName
              supplierId
              supplierName
              productId
              productName
              productCode
              qtyOnHand
              uomId
              uomName
              productCategoryId
              productcategoryName
              taxCategoryId
              taxCategoryName
              taxId
              taxName
              taxRate
              priceStd
              priceList
              twoWeekSale
              fourWeekSale
              upc
              description
              batchedProduct
              alternateUomList 
                  { alternateUomId uomId uomName }
              margin
          }     
        }`,
      },
    });
    return SupplierProducts.data.data.getSupplierProduct;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getPoDocs = async (supplierId, bunitId) => {
  try {
    const SupplierProducts = await customInstance({
      data: {
        query: `query {     
          getPO(bUnitId : "${bunitId}" ,supplierId : "${supplierId}") 
          {         
          orderId         
          docNo         
          dateOrdered         
          supplierId         
          supplierIdName         
          bUnitLocationId         
          bUnitId         
          bUnitIdName         
          islocalpurchase   
          totalNetAmount  
          totalGrossAmount  
          } 
          }`,
      },
    });
    return SupplierProducts.data.data.getPO;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getpoLineProducts = async (bunitId, supplierId, e) => {
  try {
    const SupplierProducts = await customInstance({
      data: {
        query: `query {       
          getPoLines (bunitId : "${bunitId}" supplierId: "${supplierId}" orderIds: ["${e}"] date: null, sku:null upc:null) 
              {   
              orderLineId        
              orderId         
              documentNo         
              line         
              productId         
              productSearchKey         
              productName         
              uomId         
              uomName         
              orderedQty         
              description         
              receivedqty         
              stockUomId         
              stockUomIdName         
              stockQty         
              unitPrice         
              listPrice         
              batchedProduct
              upc 
              salePrice
              taxRate{
                  csTaxID
                  name
                  rate
              }
          }   
      }`,
      },
    });
    return SupplierProducts.data.data.getPoLines;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getReturnReasons = async (clientId) => {
  try {
    const returnreasonData = await customInstance({
      data: {
        query: `query{
          getReturnReason(clientId:"${clientId}"){
            returnReasonId
            name    
          }  
        }`,
      },
    });
    return returnreasonData.data.data.getReturnReason;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getPendingRequisition = async (bunit, doctype) => {
  try {
    const returnreasonData = await customInstance({
      data: {
        query: `query { getPendingRequisition(bUnitId: "${bunit}",documentType: "${doctype}")
        {
          purchaseRequistionId
          csBUnit{
              csBunitId                                   
              name
              }
              documentNo
              requistionDate
              requiredDate
              description
         requisitionType{
                              typeId
                              name
                                    }
       csUser {
                  csUserId                        
                   username
                  }
        
           requisitionLines{
                     prequestlineId                
                     product {
                             mProductId                 
                             name
                             value
                             description
                             imageurl
                             isManual
                             shortDescription
                             salePrice
                             listPrice
                             purchasePrice
                             costPrice
                             }
                        uom {
                            csUomId                                  
                            name
                            }                       
                         mProduct{
                              mProductCategoryId  
                                 name
                                 value
                                }
                         description
                         requiredQty
                         estimatedPrice
                         estimatedtotal
                          tax
                              {
                               csTaxID                            
                               name
                               rate
                              }
                         
                          }
            supplierProduct{
                               productId
                               supplierId
                               supplierName
                               lastPurchasePrice
                               minOrderQty
                               supplierProductId
                           }   
        }
      }`,
      },
    });
    return returnreasonData.data.data.getPendingRequisition;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

const getPendingSalesOrders = async (bUnitId, documentTypeId, customerId) => {
  try {
    const pendingSalesOrder = await customInstance({
      data: {
        query: `query {
              getPendingSalesOrders(bUnitId: "${bUnitId}",documentType: "${documentTypeId}", customerId: ${customerId === null ? null : `"${customerId}"`})
              {
                  sOrderID
                  csBUnit{
                      csBunitId                                 
                      name
                      }
                      documentno
                      dateordered
                      datepromised
                      description
               salesRep{ 
                    salesRepresentId                                
                     name
                     }
                  customer{
                          sCustomerID                            
                          name
                          }
                   salesOrderLines{
                        sOrderlineID                
                        product {
                                     mProductId                              
                                     name
                                     value
                                     description
                                     imageurl
                            
                                     }
                                uom {
                                    csUomId                                   
                                    name
                                    }                       
                                 mProduct{
                                         mProductCategoryId                       
                                         name
                                         value
                                        }
                                 description
                                 qty       
                                 stockqty       
                                 requiredQty     
                                 deliveredQty    
                                  tax
                                      {
                                       csTaxID                                
                                       name
                                       rate
                                      }
                                 
              }
           }
        }`,
      },
    });
    return pendingSalesOrder.data.data.getPendingSalesOrders;
  } catch (error) {
    console.error(JSON.stringify(error, null, 2));
    return null;
  }
};

export {
  getProductCategory,
  getTaxCategory,
  getUOM,
  getSupplierCategory,
  getSupplierRegion,
  getDeliveryLocation,
  getSupplierProduct,
  getLandedCostData,
  getInventoryProduct,
  getStockCountProduct,
  getgetAgents,
  getApparelProducts,
  getSpecificApparelProduct,
  getDraftPoDocs,
  getDraftpoProducts,
  getStockAllocationData,
  getConfirmDataForStockAllocation,
  getRoleBusinessUnit,
  getPoOrderLineProducts,
  getPurchaseOrderDocData,
  getSupplierProductsList,
  getBrand,
  getPoDocs,
  getpoLineProducts,
  getReturnReasons,
  getPendingRequisition,
  getPendingSalesOrders,
};
