import React, { useEffect, useState } from "react";
import { Card, Form, Row, Col, Select, Button, Modal, Spin, message, Table } from "antd";
import moment from "moment";
import { v4 as uuid } from 'uuid'
import Axios from 'axios'
import { LoadingOutlined } from "@ant-design/icons";
import { getRequisitionTypeData, getSupplierData,getSupplierAddress } from "../../../services/generic";
import { getPendingRequisition, getRoleBusinessUnit, getDeliveryLocation } from "../../../services/custom";
import {serverUrl,genericUrl,fileDownloadUrl} from '../../../constants/serverConfig'

const { Option } = Select;

const StockAllocation = () => {
  const [bunitData, setBunitData] = useState([]);
  const [bUnitId, setBUnitId] = useState("");

  const [loading, setLoading] = useState(false);
  const [requisitionTypeData, setRequisitionTypeData] = useState([]);
  const [requisionTypeId, setRequisionTypeId] = useState(null);
  const [productData, setProductData] = useState([]);
  const [priviewData, setPriviewData] = useState([]);
  const [poSummaryVisible, setPoSummaryVisible] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [regionName, setRegionName] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [priceListId, setPriceListId] = useState("");
  const [istaxincludedFlag, setIstaxincludedFlag] = useState("");
  const [supplierAddressId,setSupplierAddressId] = useState('')
  const [uniueOrderId,SetUniueOrderId] = useState([])
  const [headerform] = Form.useForm();
  const [summaryForm] = Form.useForm();

  useEffect(() => {
    const uniqueId = uuid()
    .replace(/-/g, '')
    .toUpperCase()
    SetUniueOrderId(uniqueId)
   }, []);

  const getBusinessUnit = async () => {
    const userData = JSON.parse(window.localStorage.getItem("userData"));
    const businessUnitResponse = await getRoleBusinessUnit(userData.user_id);
    setBunitData(businessUnitResponse.userBunit);
  };

  const getSuppliers = async () => {
    const supplierResponse = await getSupplierData();
    setSupplierData(supplierResponse);
  };

  const onSelectBusinessUnit = (e) => {
    setBUnitId(e);
    getDeliverLocation(e);
  };

  const getDeliverLocation = async (e) => {
    const deliveryResponse = await getDeliveryLocation(e);
    // setDeliveryLocationList(deliveryResponse)
    setRegionName(deliveryResponse[0].bUnitLocationId);
  };

  const onSelectSupplier = (e, data) => {
    setSupplierId(e);
    setPriceListId(data.props.pricelistid);
    setIstaxincludedFlag(data.props.istaxflag);
    getSupplierAdd(e)
  };
  
  const getSupplierAdd = async(e) =>{
    const supplierAddressResponse = await getSupplierAddress(e)
    setSupplierAddressId(supplierAddressResponse[0].recordid)
  }
  const getRequisitionType = async () => {
    const Response = await getRequisitionTypeData();
    setRequisitionTypeData(Response.searchData);
  };

  const onSelectRequisitionType = (e) => {
    setRequisionTypeId(e);
  };

  const createPoCancel = () => {
    setPoSummaryVisible(false);
  };

  const getProducts = async () => {
    setLoading(true);
    const Response = await getPendingRequisition(bUnitId, requisionTypeId);
    for (let index = 0; index < Response.length; index += 1) {
      Response[index].key = Response[index].requisitionLines.prequestlineId;
    }
    console.log("Response=============>",Response)
    setProductData(Response);
    setLoading(false);
  };

  const columns = [
    {
      title: "Requisition #",
      dataIndex: "value",
      width: 100,
      render: (text, record) => {
        return record.documentNo;
      },
    },
    {
      title: "Required By",
      dataIndex: "value",
      width: 100,
      render: (text, record) => {
        return record.requiredDate === null || record.requiredDate === "" || record.requiredDate === undefined ? null : moment(record.requiredDate).format("YYYY-MM-DD");
      },
    },
    {
      title: "Customer",
      dataIndex: "value",
      width: 100,
    },
    {
      title: "Product",
      dataIndex: "value",
      width: 220,
      render: (text, record) => {
        return record.requisitionLines.product.value + " - " + record.requisitionLines.product.name;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 100,
      render: (text, record) => {
        return record.requisitionLines.description;
      },
    },
    {
      title: "Requested Qty",
      dataIndex: "value",
      width: 100,
      render: (text, record) => {
        return record.requisitionLines.requiredQty;
      },
    },
    {
      title: "Ordered Qty",
      dataIndex: "value",
      width: 100,
      render: () => {
        return 0;
      },
    },
    {
      title: "Pending Qty",
      dataIndex: "value",
      width: 100,
      render: () => {
        return 0;
      },
    },
  ];

  const onSelectProductData = (e, data) => {
    setPriviewData(data);
  };
  const rowSelectionForProducts = {
    onChange: onSelectProductData,
  };

  const onPriview = () => {
    if (priviewData.length > 0) {
      setPoSummaryVisible(true);
    } else {
      message.error("Please select products!");
    }
  };

  const createPO = () =>{
      summaryForm.validateFields()
      .then(values => {
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    setLoading(true)
    const date = new Date()
    const date1 = moment(date).format('YYYY-MM-DD')
    const arrayForMutation = []
      let uomId = ''
      let productId1 = ''
      let orderQty1 = ''
      let unitPrice = ''
      let listPrice = ''
      let taxId = ''
      let description1 = ''
      let freeqty = ''
      let margin = ''
      let discountValue=''
      let discountType=''
      let totalDiscount=''
      let grossStd
      let netStd
      let upc =''
      let marginStd = ''
      let salePrice=''
      let uniqueId = uuid()
              .replace(/-/g, '')
              .toUpperCase()
    for (let index = 0; index < priviewData.length; index++) {
    productId1 = priviewData[index].requisitionLines.product.mProductId
    uomId = priviewData[index].requisitionLines.uom.csUomId
    grossStd = 0
    netStd = 0
    orderQty1 = priviewData[index].requisitionLines.requiredQty
    unitPrice = istaxincludedFlag === "Y" ? priviewData[index].requisitionLines.product.purchasePrice : priviewData[index].requisitionLines.product.costPrice
    listPrice = priviewData[index].requisitionLines.product.listPrice
    taxId = priviewData[index].requisitionLines.tax.csTaxID
    upc = null
    salePrice = priviewData[index].requisitionLines.product.salePrice
    marginStd= 0
    description1 =
    priviewData[index].requisitionLines.product.description === null ||
    priviewData[index].requisitionLines.product.description === undefined
        ? null
        : priviewData[index].description
    freeqty = 0
    margin = 0
    discountValue = 0    
    discountType = null
    totalDiscount = 0
    arrayForMutation.push(
      `{
        
          productId : "${productId1}",
          uomId : "${uomId}",
          orderQty : ${orderQty1},
          unitPrice : ${unitPrice === null || unitPrice === undefined ? 0 : unitPrice},                                      
          listPrice : ${listPrice === null || listPrice === undefined ? 0 : listPrice},
          taxId : "${taxId}",
          description : ${description1 === null || description1 === undefined ? null : `"${description1}"`},
          freeqty : ${freeqty},
          isManual: "Y",
          margin : ${margin},
          marginStd : ${marginStd},
          discountvalue : ${discountValue},
          discountType: ${discountType === null || discountType === undefined ? null : `"${discountType}"`},       
          totalDiscount: "${totalDiscount}",
          grossstd:${grossStd},
          netstd:${netStd}
          salePrice:${salePrice === null || salePrice === undefined ? null : salePrice },
          upc:${upc === undefined || upc === null || upc === "" ? null :`"${upc}"`}
      }`,
    )
  }
  const createPoOrder = {
    query: `mutation {
      createPO(order : {
              orderId : "${uniueOrderId}",
              bunitId : "${bUnitId}",
              bunitLocationId : "${regionName}",
              supplierId : "${supplierId}",
              dateOrdered : "${date1}",
              datePromised : "${date1}",
              isTaxIncluded : "${istaxincludedFlag}",
              pricelistId : "${priceListId}",
              description : null,
              deliveryNote : "Check Quantity & Price",
              supplierAddressId : "${supplierAddressId}",
              lines : [${arrayForMutation}]
              landedCost:[]
      }) {
              type
              code
              message
              documentNo
              extraParam
      }
  }`,
  }
  Axios({
    url: serverUrl,
    method: 'POST',
    data: createPoOrder,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${newToken.access_token}`,
    },
  }).then(response => {
    if (response.data.data !== null) {
      const Status = response.data.data.createPO.type
      const messageForSuccess = response.data.data.createPO.message
      if (Status === 'Success') {
        setLoading(false)
        // message.success(messageForSuccess)
        setPoSummaryVisible(false)
        const recordId = response.data.data.createPO.extraParam  
        headerform.resetFields();
        SetUniueOrderId(uniqueId)
        setProductData([])
        setPriviewData([])
        getPrintCofirmation(recordId,messageForSuccess)
      }else{
        message.error(messageForSuccess)
        setLoading(false)
      }
    }else{
      message.error('getting error while creating a PO')
      setLoading(false)
    }
    })
      })
  }

  const getPrintCofirmation = (recordId,messageForSuccess) => {
    Modal.confirm({
      title: `${messageForSuccess}`,
      content: 'Do you want take Printout',
      okText: 'Yes',
      icon:null,
      cancelText: 'No',
      onOk() {
        getPrintPdffile(recordId)
      },
      onCancel() {
        setPoSummaryVisible(false)
        setProductData([])
      },
    });
  }
  
  const getPrintPdffile = (recordId) => {
    const newToken = JSON.parse(localStorage.getItem("authTokens"));
    const RoleId = window.localStorage.getItem('userData')
    const getPrintPdfData = {
      query: `query {reportTemplate(ad_tab_id:"EE797F0AD47E41A08CFBC7867F538661",recordId:"${recordId}")}`,
    }
  
    Axios({
      url: genericUrl,
      method: 'POST',
      async: true,
      crossDomain: true,
      data: getPrintPdfData,
  
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${newToken.access_token}`,
        RoleId: `${RoleId.role_id}`,
      },
    }).then(response => {
      if (
        response.data.data.reportTemplate === null ||
        response.data.data.reportTemplate === 'null' ||
        response.data.data.reportTemplate === ''
      ) {
        
      } else {
        getPrintCommand(response.data.data.reportTemplate)
      }
    })
  }
  
  const getPrintCommand = fileName => {
    setProductData([])
    setPoSummaryVisible(false)
    Axios({
      url: `${fileDownloadUrl}`.concat(`${fileName}`),
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.setAttribute('id', 'downloadlink')
      link.href = fileURL
      link.setAttribute('download', `${fileName}`)
      link.click()
    })
  }

  const summaryDiv = (
    <Card>
      <Form layout="vertical" form={summaryForm} name="summaryForm">
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item 
            name="summsupplier"
            label="Supplier"
            rules={[
                {
                  required: true,
                  message: "Please select supplier!",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onSelect={onSelectSupplier}
                onFocus={getSuppliers}
              >
                {supplierData.map((data, index) => (
                  <Option key={data.recordid} value={data.recordid} title={data.name} istaxflag={data.istaxincluded} pricelistid={data.p_pricelist_id}>
                    {data.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
            <p />
        </Row>
        <Row>
            <p />
        </Row>
      </Form>
      <div>
        <Table columns={columns} dataSource={priviewData} style={{ fontSize: "12px" }} size="small" sticky={true} scroll={{ y: "40vh", x: "100%" }} pagination={false} />
      </div>
    </Card>
  );

  return (
    <div>
      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
        <Row>
          <Col span={12}>
            <h2 style={{ fontWeight: "700", fontSize: "16px", color: "rgb(0 0 0 / 65%)", marginBottom: "0px", marginTop: "1%" }}>Pending Requisition</h2>
          </Col>
          <Col span={12}>
            <span style={{ float: "right" }}>
              <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }} onClick={getProducts}>
                View
              </Button>
              <Button style={{ marginBottom: "8px", backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }} onClick={onPriview}>
                Create Po
              </Button>
            </span>
          </Col>
        </Row>
        <Card style={{ marginBottom: "8px" }}>
          <Form layout="vertical" form={headerform} /* onFinish={onFinish} */>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item name="businessUnit" label="Business unit" style={{ marginBottom: "8px" }}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getBusinessUnit}
                    onSelect={onSelectBusinessUnit}
                  >
                    {bunitData.map((data) => (
                      <Option key={data.csBunitId} value={data.csBunitId} title={data.bUnitName}>
                        {data.bUnitName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item name="requisitionType" label="Requisition Type" style={{ marginBottom: "8px" }}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getRequisitionType}
                    onSelect={onSelectRequisitionType}
                  >
                    {requisitionTypeData.map((data) => (
                      <Option key={data.RecordID} value={data.RecordID} title={data.Name}>
                        {data.Name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}>
          <div>
            <Table
              rowSelection={{
                ...rowSelectionForProducts,
              }}
              columns={columns}
              dataSource={productData}
              style={{ fontSize: "12px" }}
              size="small"
              sticky={true}
              scroll={{ y: "60vh", x: "100%" }}
              pagination={false}
            />
          </div>
        </Card>
      </Spin>
      <Modal
        visible={poSummaryVisible}
        closable={null}
        centered
        width="90%"
        footer={[
          <Button key="back" onClick={createPoCancel}>
            Cancel
          </Button>,
          <Button
            loading={loading}
              onClick={createPO}
          >
            Confirm
          </Button>,
        ]}
      >
        <h3 style={{ textAlign: "center" }}>Pending Requisition Summary</h3>
        <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
          {summaryDiv}
        </Spin>
      </Modal>
    </div>
  );
};

export default StockAllocation;
